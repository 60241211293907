import React from "react";

import { Box } from "@mui/material";

import { Stop } from "types";
import FlightInfoCollapsibleCard from "components/shared/FlightInfoCard/FlighInfoCollapsibleCard";
import { getStopFlightType } from "globals/utils/flight";
import StopChange from "./StopChange";

type StopItemProps = {
  stop: Stop;
  label: string;
  onStopDetailsClick: () => void;
  oldPickUpStop?: Stop;
  oldDropOffStop?: Stop;
  isOldStopView: boolean;
  isLastStop: boolean;
};

function StopItemNotification(props: StopItemProps) {
  const {
    stop,
    oldPickUpStop,
    oldDropOffStop,
    label,
    onStopDetailsClick,
    isOldStopView,
    isLastStop,
  } = props;

  const { trackedFlight } = stop;

  const { trackedFlight: oldPickUpTrackedFlight } = oldPickUpStop || {};
  const { trackedFlight: oldDropOffTrackedFlight } = oldDropOffStop || {};
  const isPickUp = stop.stopIndex === 1;

  return (
    <Box display="flex" flexDirection="column">
      <StopChange
        oldPickUpStop={oldPickUpStop}
        oldDropOffStop={oldDropOffStop}
        newStop={stop}
        label={label}
        onStopDetailsClick={onStopDetailsClick}
        isOldStopView={isOldStopView}
        isLastStop={isLastStop}
      />
      {trackedFlight && isOldStopView && (
        <FlightInfoCollapsibleCard
          flightType={getStopFlightType(stop)}
          trackedFlight={trackedFlight}
        />
      )}
      {trackedFlight && !isOldStopView && (isLastStop || isPickUp) && (
        <FlightInfoCollapsibleCard
          flightType={getStopFlightType(stop)}
          trackedFlight={trackedFlight}
        />
      )}
      {oldPickUpTrackedFlight && !isOldStopView && isPickUp && (
        <FlightInfoCollapsibleCard
          flightType={getStopFlightType(oldPickUpStop)}
          trackedFlight={oldPickUpTrackedFlight}
          isOutdatedFlight
        />
      )}
      {oldDropOffTrackedFlight && !isOldStopView && isLastStop && (
        <FlightInfoCollapsibleCard
          flightType={getStopFlightType(oldDropOffStop)}
          trackedFlight={oldDropOffTrackedFlight}
          isOutdatedFlight
        />
      )}
    </Box>
  );
}

export default StopItemNotification;
