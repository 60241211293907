/**
 * @file VehicleBlock.tsx
 * Vehicle section of driver dispatch view
 *
 * components:
 *  VehicleBlock
 *  VehicleCard
 */
import React from "react";

import { Box, Card, Typography, Avatar, Divider } from "@mui/material";

import { Vehicle } from "types";
import { grayDark, black } from "globals/design-system/colors";

type VehicleBlockProps = {
  vehicle: Vehicle;
};

function VehicleBlock(props: VehicleBlockProps) {
  const { vehicle } = props;

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h3">Vehicle</Typography>
        <Box my={2}>{vehicle && <VehicleCard vehicle={vehicle} />}</Box>
      </Box>
    </Box>
  );
}

export default VehicleBlock;

// Note: Made VehicleCard as separate component so it will be easier
// separate into separate file when driver app functionality
// is expanded and this is reused elsewhere.

type VehicleCardProps = {
  vehicle: Vehicle;
};
function VehicleCard(props: VehicleCardProps) {
  const { vehicle } = props;

  // custom Styles
  const subtitleCustomStyle = {
    color: grayDark,
    lineHeight: 1.5,
  };
  const carParamCustomStyle = { color: black, lineHeight: 1.35 };

  const vehicleName = vehicle.name;
  const vehicleType = vehicle.vehicleType.typeName;
  const vehiclePlateNo = vehicle.licensePlate;
  const vehiclePic =
    vehicle.photos && vehicle.photos[0] && vehicle.photos[0].url;

  return (
    <Card variant="outlined">
      <Box display="flex" flexDirection="row" flex="1" p={2}>
        <Box display="flex">
          <Avatar
            variant="square"
            alt="Vehicle Preview"
            src={vehiclePic || ""}
            sx={{ borderRadius: "4px", width: 80, height: 55 }}
          />
        </Box>
        <Box display="flex" flex="1" flexDirection="column" ml={2}>
          <Box mb={1}>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              {vehicleName}
            </Typography>
          </Box>

          <Divider />
          <Box display="flex" flexDirection="column" mt={1}>
            <Typography variant="overline" sx={subtitleCustomStyle}>
              VEHICLE TYPE
            </Typography>
            <Typography variant="caption" sx={carParamCustomStyle}>
              {vehicleType}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" mt={1}>
            <Typography variant="overline" sx={subtitleCustomStyle}>
              PLATE NUMBER
            </Typography>
            <Typography variant="caption" sx={carParamCustomStyle}>
              {vehiclePlateNo}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
