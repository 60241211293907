import React from "react";
import { Box, Typography } from "@mui/material";

import { SquareChip } from "components/ui";
import {
  WheelIcon,
  MapIcon,
  TruckIcon,
  SyncIcon,
  BriefcaseIcon,
  ClipboardIcon,
  SeatIcon,
  PlaneIcon,
} from "globals/design-system/icons";
import { primaryMainColor } from "globals/design-system/theme";
import { Alert } from "types";

const dispatchAlertsArr = [
  {
    dbName: "newDriver",
    name: "New Driver",
    icon: WheelIcon,
  },
  {
    dbName: "uniqueLocation",
    name: "Location",
    icon: MapIcon,
  },
  {
    dbName: "airport",
    name: "Airport",
    icon: PlaneIcon,
  },
  {
    dbName: "largeVehicle",
    name: "Large Vehicle",
    icon: TruckIcon,
  },
  {
    dbName: "partner",
    name: "Partner",
    icon: SyncIcon,
  },
  {
    dbName: "luggage",
    name: "Luggage",
    icon: BriefcaseIcon,
  },
  {
    dbName: "signage",
    name: "Signage",
    icon: ClipboardIcon,
  },
  {
    dbName: "carSeat",
    name: "Car Seat",
    icon: SeatIcon,
  },
];

type TripAlertsBlockProps = {
  alerts: Alert;
};

function TripAlertsBlock(props: TripAlertsBlockProps) {
  const { alerts } = props;

  const tripAlerts = dispatchAlertsArr.filter((alert) => alerts[alert.dbName]);

  return (
    <Box mb={2}>
      <Typography variant="h3">Alerts</Typography>
      <Box
        display="flex"
        flexDirection="row"
        flex="1"
        maxWidth="640px"
        flexWrap="wrap"
        justifyContent="flex-start"
        my={2}
      >
        {tripAlerts.length ? (
          tripAlerts.map((alert, index) => (
            <Box key={index} m={0.5}>
              <SquareChip
                label={alert.name}
                variant="dispatchAlert"
                color={primaryMainColor}
                backgroundColor="transparent"
                icon={alert.icon}
              />
            </Box>
          ))
        ) : (
          <Typography>There are no trip alerts added yet.</Typography>
        )}
      </Box>
    </Box>
  );
}

export default TripAlertsBlock;
