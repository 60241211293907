import React from "react";

import { Box } from "@mui/material";

import { moovsBlue } from "globals/design-system/colors";

const NotificationIcon = () => {
  return (
    <Box width="5px" height="5px" borderRadius={5} bgcolor={moovsBlue}></Box>
  );
};

export default NotificationIcon;
