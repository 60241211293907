import React from "react";
import isUndefined from "lodash/isUndefined";

import { Box, Button, Typography } from "@mui/material";

import { Device, useMobileOsDetector } from "globals/hooks/useMobileOsDetector";
import { useDriver } from "globals/hooks/useDriver";
import { granite, moovsBlueSelected } from "globals/design-system/colors";
import MoovsMark from "globals/design-system/illustrations/moovsMark.svg";
import { useAnalytics } from "globals/hooks/useAnalytics";
import { useQueryParams } from "globals/hooks/useQueryParams";

const ICON_DIMENSION = 42;

function DriverPlatformBanner() {
  // hooks
  const device = useMobileOsDetector();
  const { driver, driverLoading } = useDriver();
  const { track } = useAnalytics();
  const queryParams = useQueryParams();

  // derived state
  const hasDriverPlatformInstalled =
    driver?.operator.moovsMarketApps.driverApp.active;

  const isNotMobileDevice = isUndefined(device);

  const hideBannerForDriverAppEmbed = queryParams.get("navigate") === "hidden";

  // event handlers
  const handleInstallClick = () => {
    if (device === Device.iOS) {
      window.open("https://apps.apple.com/lc/app/moovs-driver/id1613250751");
    }

    if (device === Device.Android) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.moovs.driver"
      );
    }

    track("driverLink_appInstalled");
  };

  if (
    isNotMobileDevice ||
    !hasDriverPlatformInstalled ||
    driverLoading ||
    hideBannerForDriverAppEmbed
  )
    return null;

  return (
    <Box
      sx={{
        backgroundColor: moovsBlueSelected,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        p: 2,
      }}
    >
      <Box
        component="img"
        sx={{ height: ICON_DIMENSION, width: ICON_DIMENSION }}
        src={MoovsMark}
        alt="Moovs Logo"
      />
      <Box ml={1.5} mr={2} display="flex" flexDirection="column">
        <Typography variant="subtitle1" sx={{ mb: "3px" }}>
          Driver App
        </Typography>
        <Typography variant="caption" color={granite}>
          Install the Moovs Driver App now for the best driver experience!
        </Typography>
      </Box>
      <Box>
        <Button variant="contained" onClick={handleInstallClick}>
          Install
        </Button>
      </Box>
    </Box>
  );
}

export default DriverPlatformBanner;
