/**
 * @file TripNotesBlock.tsx
 * Driver Note section of driver dispatch view
 *
 * components:
 *  TripNotesBlock
 */
import React from "react";
import some from "lodash/some";

import { Box, Typography } from "@mui/material";
import { grayDark } from "globals/design-system/colors";
import LuggageBlock from "./components/LuggageBlock";
import { ChildSeatsNoteBlock } from "./components";

type TripNotesBlockProps = {
  note: string;
  luggage: {
    carryOnLuggage: number;
    checkedLuggage: number;
    oversizeLuggage: number;
  };
  childSeats?: { [key: string]: number };
};

function TripNotesBlock(props: TripNotesBlockProps) {
  const { note, luggage, childSeats } = props;

  // derived state
  const hasLuggage = some(luggage, (luggageType) => !!luggageType);
  const hasChildSeats = some(childSeats, (qty) => qty > 0);

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h3">Trip Notes</Typography>
        <Box my={2}>
          {note ? (
            <Typography variant="body2" sx={{ whiteSpace: "break-spaces" }}>
              {note}
            </Typography>
          ) : (
            <Typography variant="body2" color={grayDark}>
              No Trip Notes
            </Typography>
          )}
        </Box>
        {hasLuggage && <LuggageBlock luggage={luggage} />}
        {hasChildSeats && <ChildSeatsNoteBlock {...childSeats} />}
      </Box>
    </Box>
  );
}

export default TripNotesBlock;
