import { createTheme, Components } from "@mui/material/styles";

import {
  alabaster,
  errorRed,
  grayDark,
  grayMedium,
  grayLight,
  successGreen,
  moovsBlue,
  moovsBlueSelected,
  warningYellow,
  white,
  black,
  granite,
} from "./colors";

/* Custom Swoop Brand Colors */
export const primaryMainColor = moovsBlue;
export const primaryHoverColor = grayLight; //moovsBlueLight exists, but not used
export const primaryDisabledColor = moovsBlueSelected;

export const secondaryMainColor = primaryMainColor;

/* Moovs zIndex Values */
export const confettiParticlesZIndex = 99999999;

/* Override mui Styles */
const components: Components = {
  MuiAutocomplete: {
    styleOverrides: {
      root: { width: "100%" },
      inputRoot: {
        backgroundColor: white,
      },
    },
  },
  MuiBadge: {
    styleOverrides: {
      colorError: {
        backgroundColor: errorRed,
        border: "1px solid #FFFFFF",
        fontSize: 11,
        lineHeight: 18,
        height: 18,
        minWidth: 18,
        padding: "0 4px",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      contained: {
        minHeight: 44,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
        "&:hover": {
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
        },
      },
      outlinedPrimary: {
        minHeight: 44,
        borderWidth: "2px",
        "&:hover": {
          borderWidth: "2px",
        },
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderColor: grayLight,
        boxShadow: "0 4px 15px 0 rgba(0, 0, 0, 0.03)",
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        backgroundColor: grayLight,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        "& fieldset": {
          borderColor: grayMedium,
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      outlined: {
        borderColor: grayLight,
        boxShadow: "0 4px 15px 0 rgba(0, 0, 0, 0.03)",
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: 12,
        lineHeight: 1.5,
        padding: "8px 16px",
        backgroundColor: granite,
        borderRadius: 4,
      },
    },
  },
};

const theme = createTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1140, //1280
      xl: 1920,
    },
  },
  palette: {
    background: {
      default: alabaster,
      paper: white,
    },
    primary: {
      main: primaryMainColor,
      contrastText: "#fff",
    },
    secondary: {
      main: secondaryMainColor,
      contrastText: "#000",
    },
    error: { main: errorRed },
    warning: { main: warningYellow },
    success: { main: successGreen },
    action: {
      hover: primaryHoverColor,
    },
    text: {
      primary: black,
    },
  },
  typography: {
    fontFamily: "Poppins",
    h1: {
      fontWeight: 600,
      fontSize: "2rem", // 32,
      lineHeight: 1.25,
      letterSpacing: "-0.031rem",
    },
    h2: {
      fontWeight: 600,
      fontSize: "1.5rem", // 24,
      lineHeight: 1.33,
      letterSpacing: "-0.031rem",
    },
    h3: {
      fontWeight: 600,
      fontSize: "1.25rem", // 20,
      lineHeight: 1.2,
      letterSpacing: "-0.009rem",
    },
    h4: {
      fontWeight: 600,
      fontSize: "1rem", // 16,
      lineHeight: 1.5,
      letterSpacing: "0.006rem",
    },
    h5: {
      fontWeight: 600,
      fontSize: "0.875rem", // 14,
      lineHeight: 1.43,
      letterSpacing: "0.025rem",
      textTransform: "uppercase",
    },
    h6: {
      fontWeight: 600,
      fontSize: "0.75rem", // 12,
      lineHeight: 1.5,
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: "1rem", // 16,
      lineHeight: 1.5,
      letterSpacing: "-0.006rem",
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: "0.875rem", // 14,
      lineHeight: 1.43,
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem", // 16,
      lineHeight: 1.33,
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.875rem", // 14,
      lineHeight: 1.43,
    },
    button: {
      textTransform: "none",
      fontWeight: 500,
      fontSize: "1rem", // 16,
      letterSpacing: "-0.016rem",
    },
    caption: {
      fontWeight: 400,
      fontSize: "0.75rem", // 12,
      lineHeight: 1.5,
    },
    overline: {
      fontWeight: 500,
      fontSize: "0.75rem", // 12,
      lineHeight: 1.5,
      letterSpacing: "0.016rem",
      color: grayDark,
    },
  },
  spacing: 8,
  components,
});

export default theme;
