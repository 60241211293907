import moment from "moment-timezone";

import airportTimeZone from "./airportTimeZone.json";
import { Stop, Trip } from "types";

// builds url for route in Google Maps
export function getGoogleMapsURL(address: string) {
  // reference: https://stackoverflow.com/questions/15042283/current-location-google-maps-link-to-directions
  const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
    address
  )}`;

  return url;
}

export function convertMinutesToHoursMinutes(inputMinutes: number) {
  const hours = Math.floor(inputMinutes / 60);
  const minutes = Math.round(inputMinutes % 60);

  let result = "";

  if (hours) {
    result += `${hours}h`;
  }
  if (minutes) {
    result += ` ${minutes}min`;
  }

  return result || "N/A";
}

// takes current time and corrects offset by browser timezone.
// then manually replaces the timezone with UTC
export function convertTimeToUTC(time) {
  const dateTimeString = (
    time?._isAMomentObject
      ? time.tz(moment.tz.guess(true))
      : moment(time).tz(moment.tz.guess(true))
  ).format("MM-DD-YYYY hh:mm:ss A");

  return moment.utc(dateTimeString, "MM-DD-YYYY hh:mm:ss A");
}

export function applyUTCOffsetToTime(time: any, event: "add" | "subtract") {
  if (!time) return time;
  const isMomentObj = time?._isAMomentObject;
  const timezoneGuess = moment.tz.guess();

  const UTCOffset = moment.tz
    .zone(timezoneGuess)
    .utcOffset(isMomentObj ? time.unix() * 1000 : moment(time).unix() * 1000);

  return isMomentObj
    ? time[event](UTCOffset, "m")
    : moment(time)[event](UTCOffset, "m");
}

export function convertTimeToLocalAirlineTimezone(
  unixTimeInSeconds: number,
  icao: string
) {
  return moment(unixTimeInSeconds * 1000).tz(airportTimeZone[icao]);
}

export function currency(money: number) {
  if (money === undefined) return "-";

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(money);
}

export enum DispatchStatusEnum {
  pending = "Pending",
  confirmed = "Confirmed",
  otw = "On The Way",
  "on-location" = "On Location",
  pob = "Passenger On Board",
  done = "Done",
  cancelled = "Cancelled",
}

export const getStopLabel = (stops: Partial<Stop>[], stopIndex: number) => {
  if (stopIndex === 1) return "Pick-up";
  if (stopIndex === stops.length) return "Drop off";

  return `Stop ${stopIndex - 1}`;
};

export const getStopLocation = (stop?: Partial<Stop>) => {
  if (!stop) return undefined;

  return stop?.variant === "address"
    ? stop?.locationAlias || stop?.location
    : `${stop?.airport?.iataCode} - ${stop?.location}`;
};

export type SeatType = {
  boosterSeatQuantity: number | null;
  rearFacingSeatQuantity: number | null;
  forwardFacingSeatQuantity: number | null;
};

export const getChildSeats = (
  oldSeats: SeatType,
  newSeats: SeatType,
  isOld: boolean
) => {
  const result: SeatType = {
    boosterSeatQuantity: null,
    rearFacingSeatQuantity: null,
    forwardFacingSeatQuantity: null,
  };

  const seatTypes = [
    "boosterSeatQuantity",
    "rearFacingSeatQuantity",
    "forwardFacingSeatQuantity",
  ];

  for (const type of seatTypes) {
    if (oldSeats[type] !== newSeats[type]) {
      result[type] = isOld ? oldSeats[type] || 0 : newSeats[type] || 0;
    } else {
      result[type] = 0;
    }
  }

  return result;
};

export const getPassengerInfoFromTrip = (trip: Trip) => {
  const isTemporaryPassenger = !!trip.temporaryPassenger?.name;

  const passengerName = isTemporaryPassenger
    ? trip.temporaryPassenger.name
    : `${trip.contact.firstName} ${trip.contact.lastName}`;

  const greetingSignName = isTemporaryPassenger
    ? trip.temporaryPassenger.name
    : trip.contact.lastName || trip.contact.firstName;

  const passengerMobilePhone = isTemporaryPassenger
    ? trip.temporaryPassenger.mobilePhone
    : trip.contact.mobilePhone;

  return {
    isTemporaryPassenger,
    passengerName,
    greetingSignName,
    passengerMobilePhone,
  };
};
