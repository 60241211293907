import React from "react";

import { Box, useMediaQuery, useTheme } from "@mui/material";

import { FlightInfoPlaneIcon } from "globals/design-system/icons";

import flightStatusMap from "./flightStatusMap";
import { FlightStatus } from "types";
import { grayMedium } from "globals/design-system/colors";

type FlightProgressDisplayType = {
  flightStatus: FlightStatus;
};

function FlightProgressDisplay(props: FlightProgressDisplayType) {
  const { flightStatus } = props;
  const { planeBorderColor, planeFillColor, planePosition } =
    flightStatusMap(flightStatus);

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  return (
    <Box {...(!isMobileView && { px: 4 })} display="flex" alignItems="center">
      <hr
        style={{
          flex: planePosition === "start" ? 0 : 1,
          height: 2,
          borderWidth: 0,
          color: grayMedium,
          backgroundColor: grayMedium,
        }}
      />
      <Box
        style={{ width: 45, height: 45 }}
        {...(planePosition === "start" && { mr: 2 })}
        {...(planePosition === "end" && { ml: 2 })}
        {...(planePosition === "middle" && { mx: 2 })}
      >
        <FlightInfoPlaneIcon
          borderColor={planeBorderColor}
          fillColor={planeFillColor}
        />
      </Box>
      <hr
        style={{
          flex: planePosition === "end" ? 0 : 1,
          height: 2,
          borderWidth: 0,
          color: grayMedium,
          backgroundColor: grayMedium,
        }}
      />
    </Box>
  );
}

export default FlightProgressDisplay;
