import React from "react";
import { Helmet } from "react-helmet";

import { Typography, Box } from "@mui/material";

function FourZeroFourPage() {
  return (
    <>
      <Helmet>
        <title>404</title>
      </Helmet>
      <Box
        position="fixed"
        display="flex"
        flexDirection="column"
        flex="1"
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h2">404</Typography>
        <Typography variant="body1">
          Sorry! This page does not exist.
        </Typography>
      </Box>
    </>
  );
}

export default FourZeroFourPage;
