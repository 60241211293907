import React from "react";

import { Typography, Tab, Tabs, Box } from "@mui/material";

import { black } from "globals/design-system/colors";

type TripTabsProps = {
  operatorRoutesTotalCount: {
    newTotalCount?: number;
    completedTotalCount?: number;
    upcomingTotalCount?: number;
  };
  onTabChange: (event: React.SyntheticEvent<{}>, tabName: string) => void;
  tab: string;
};

function TripTabs(props: TripTabsProps) {
  const { operatorRoutesTotalCount = {}, onTabChange, tab } = props;

  const { newTotalCount, completedTotalCount, upcomingTotalCount } =
    operatorRoutesTotalCount;

  if (!tab) return null;

  return (
    <Box display="flex" justifyContent="center">
      <Box maxWidth="600px" width="100%">
        <Tabs variant="fullWidth" value={tab} onChange={onTabChange}>
          <Tab
            value="NEW"
            label={
              <Box>
                <Typography variant="overline">NEW</Typography>
                <Typography
                  variant="body1"
                  fontSize={24}
                  color={tab !== "NEW" && black}
                >
                  {newTotalCount}
                </Typography>
              </Box>
            }
          />
          <Tab
            value="UPCOMING"
            label={
              <Box>
                <Typography variant="overline">UPCOMING</Typography>
                <Typography
                  variant="body1"
                  fontSize={24}
                  color={tab !== "UPCOMING" && black}
                >
                  {upcomingTotalCount}
                </Typography>
              </Box>
            }
          />
          <Tab
            value="COMPLETED"
            label={
              <Box>
                <Typography variant="overline">COMPLETED</Typography>
                <Typography
                  variant="body1"
                  fontSize={24}
                  color={tab !== "COMPLETED" && black}
                >
                  {completedTotalCount}
                </Typography>
              </Box>
            }
          />
        </Tabs>
      </Box>
    </Box>
  );
}

export default TripTabs;
