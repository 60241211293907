/**
 * @file CircleAvatar.tsx
 * Restyling of Avatar component with custom
 * props to be used throughout the site for
 * user customization.
 *
 * components:
 *  Circle Avatar
 *
 * variables:
 *  'alt',
 *    accepts a string used for img alt tag
 *  'src',
 *    accepts a string used for img src
 *  'placeholder',
 *    accepts a string or string[]. Takes first
 *    initial to use in Avatar
 *  'size',
 *    accepts 'small', 'medium', 'large' and 'xl
 *
 * author: sienag
 */
import React from "react";

import { Avatar } from "@mui/material";
import { grayLight, grayDark } from "globals/design-system/colors";
import theme from "globals/design-system/theme";

function CircleAvatar({
  alt,
  size = "medium", // default
  src,
  placeholder,
}: {
  alt?: string;
  size?: "small" | "medium" | "large" | "xl";
  src?: string;
  placeholder?: string | string[];
}) {
  let initials = placeholder ? placeholder[0] : "";
  if (Array.isArray(placeholder)) {
    initials = `${placeholder[0][0] || ""}${placeholder[1][0] || ""}`;
  }

  // custom styles
  const avatarSizeCustomStyles = (size) => {
    switch (size) {
      case "small":
        return {
          width: theme.spacing(4),
          height: theme.spacing(4),
          fontSize: 13,
        };
      case "medium":
        return {
          width: theme.spacing(5),
          height: theme.spacing(5),
          fontSize: 14,
        };
      case "large":
        return {
          width: theme.spacing(8),
          height: theme.spacing(8),
          fontSize: 24,
        };
      case "xl":
        return {
          width: theme.spacing(9),
          height: theme.spacing(9),
          fontSize: 32,
        };
    }
  };

  return (
    <Avatar
      alt={alt}
      src={src}
      sx={{
        backgroundColor: grayLight,
        color: grayDark,
        letterSpacing: "0.5px",
        fontWeight: 500,
        ...avatarSizeCustomStyles(size),
      }}
    >
      {initials && initials.toUpperCase()}
    </Avatar>
  );
}

export default CircleAvatar;
