import React from "react";

type SignIconProps = {
  color?: string;
  size?: "small";
};

const OpenIcon = (props: SignIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_105_255872)">
          <path
            d="M4.56886 5.53649L2.61335 5.52384C2.35403 5.52217 2.10463 5.62862 1.92003 5.81977C1.73542 6.01093 1.63072 6.27113 1.62896 6.54314L1.58255 13.7225C1.58079 13.9945 1.68212 14.256 1.86424 14.4495C2.04636 14.6431 2.29436 14.7527 2.55368 14.7544L15.2645 14.8366C15.5238 14.8383 15.7732 14.7318 15.9578 14.5407C16.1425 14.3495 16.2472 14.0893 16.2489 13.8173L16.2953 6.63795C16.2971 6.36594 16.1957 6.1044 16.0136 5.91088C15.8315 5.71735 15.5835 5.60769 15.3242 5.60601L14.3464 5.59969M14.3033 12.2662L9.41455 12.2346M14.3199 9.70217L11.3866 9.68321M6.46696 10.5998L4.04499 10.9465L4.40687 8.41071L10.6715 1.9239C10.8086 1.78192 10.971 1.66964 11.1495 1.59348C11.3279 1.51732 11.5189 1.47876 11.7115 1.48C11.9041 1.48125 12.0946 1.52227 12.272 1.60074C12.4494 1.6792 12.6104 1.79357 12.7457 1.9373C12.8809 2.08104 12.9879 2.25134 13.0604 2.43846C13.1329 2.62559 13.1695 2.82589 13.1682 3.02792C13.1669 3.22995 13.1277 3.42976 13.0528 3.61593C12.9778 3.80211 12.8687 3.97101 12.7316 4.11298L6.46696 10.5998Z"
            stroke={color || "#565656"}
            strokeWidth="1.33"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_105_255872">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(1.00391 0.0771484) rotate(0.370377)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.4 8.15386H2.46667C2.07768 8.15386 1.70463 8.31594 1.42958 8.60446C1.15452 8.89298 1 9.28429 1 9.69232V20.4615C1 20.8696 1.15452 21.2609 1.42958 21.5494C1.70463 21.8379 2.07768 22 2.46667 22H21.5333C21.9223 22 22.2954 21.8379 22.5704 21.5494C22.8455 21.2609 23 20.8696 23 20.4615V9.69232C23 9.28429 22.8455 8.89298 22.5704 8.60446C22.2954 8.31594 21.9223 8.15386 21.5333 8.15386H20.0667M20.0667 18.1538H12.7333M20.0667 14.3077H15.6667M8.29618 15.7303L4.66667 16.2739L5.18489 12.4667L14.5188 2.67591C14.723 2.46162 14.9656 2.29164 15.2325 2.17566C15.4994 2.05969 15.7855 2 16.0744 2C16.3633 2 16.6494 2.05969 16.9163 2.17566C17.1832 2.29164 17.4258 2.46162 17.63 2.67591C17.8343 2.8902 17.9964 3.1446 18.1069 3.42458C18.2175 3.70457 18.2744 4.00465 18.2744 4.30771C18.2744 4.61076 18.2175 4.91084 18.1069 5.19083C17.9964 5.47081 17.8343 5.72521 17.63 5.9395L8.29618 15.7303Z"
        stroke={color || "#565656"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OpenIcon;
