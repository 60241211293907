import React from "react";

type WheelIconProps = {
  color?: string;
  size?: "small";
};
const WheelIcon = (props: WheelIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.33333 6C5.24667 5.08429 10.7533 5.08428 13.6667 5.99999M2.66667 7.98537C2.75143 7.97926 2.83674 7.97593 2.92259 7.97593C3.86532 7.97593 4.76943 8.35645 5.43604 9.03378C6.10264 9.71111 6.47714 10.6298 6.47714 11.5877C6.47737 11.8383 6.45115 12.0883 6.39894 12.3333M9.59122 12.3333C9.53723 12.0887 9.51172 11.8384 9.51521 11.5877C9.51521 10.6298 9.8897 9.71111 10.5563 9.03378C11.2229 8.35645 12.127 7.97593 13.0698 7.97593C13.1682 7.97593 13.2376 7.99093 13.3333 7.99926M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
          stroke={color || "#B3B3B3"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.55556 8.66666C7.41112 7.14048 16.5889 7.14047 21.4444 8.66665M3.11111 13.6423C3.25238 13.6321 3.39456 13.6265 3.53766 13.6265C5.10886 13.6265 6.61572 14.2607 7.72673 15.3896C8.83774 16.5185 9.46189 18.0496 9.46189 19.6461C9.46228 20.0639 9.41859 20.4805 9.33156 20.8889M14.652 20.8889C14.5621 20.4811 14.5195 20.064 14.5253 19.6461C14.5253 18.0496 15.1495 16.5185 16.2605 15.3896C17.3715 14.2607 18.8784 13.6265 20.4496 13.6265C20.6136 13.6265 20.7294 13.6515 20.8889 13.6654M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="12"
        cy="12"
        r="1"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WheelIcon;
