/**
 * App.tsx
 * Entry point to Moovs Driver App.
 * Wraps App with MaterialUI, ReactRouter and custom Swoop Providers.
 *
 * components:
 *  App
 */

import React from "react";
import { BrowserRouter } from "react-router-dom";

import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";

import { SnackbarProvider } from "./globals/hooks/useSnackbar";
import { SegmentProvider } from "globals/hooks/useSegment";
import { useForceReload } from "./globals/hooks/useForceReload";
import Router from "./routes/Router";

function App() {
  // force reload on pinned iOS devices when serving stale cached frontend
  useForceReload();

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <SnackbarProvider>
        <SegmentProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </SegmentProvider>
      </SnackbarProvider>
    </LocalizationProvider>
  );
}

export default App;
