import React from "react";

import { Card, Box, Avatar, Typography } from "@mui/material";

import {
  grayDark,
  black,
  grayMedium,
  white,
  moovsBlueLightNotification,
  moovsBlueSelectedNotification,
} from "globals/design-system/colors";
import NotificationIcon from "globals/design-system/icons/info/NotificationIcon";
import { Vehicle } from "types";

type VehicleCardProps = {
  vehicle: Vehicle;
  oldVehicle?: Vehicle;
};
function VehicleCard(props: VehicleCardProps) {
  const { vehicle, oldVehicle } = props;

  // custom Styles
  const subtitleCustomStyle = {
    color: grayDark,
    marginTop: "5px",
    lineHeight: "16px",
    fontWeight: 500,
    fontSize: "12px",
  };

  const oldVehicleStyle = {
    color: grayDark,
    lineHeight: "16px",
    fontWeight: 400,
    fontSize: "12px",
  };

  const carParamCustomStyle = {
    color: black,
    lineHeight: "16px",
    fontWeight: 400,
    fontSize: "12px",
  };

  const vehicleName = vehicle.name;
  const vehicleType = vehicle.vehicleType.typeName;
  const vehiclePlateNo = vehicle.licensePlate;
  const vehiclePic =
    vehicle.photos && vehicle.photos[0] && vehicle.photos[0].url;
  const hasVehicleChanged =
    oldVehicle && vehicle && vehicleType !== oldVehicle.vehicleType.typeName;

  return (
    <Card
      variant="outlined"
      sx={{
        borderColor: grayMedium,
        background: white,
        borderRadius: "4px",
        padding: "8px",
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        flex="1"
        p={2}
        {...(hasVehicleChanged
          ? {
              border: 0.5,
              borderColor: moovsBlueLightNotification,
              bgcolor: moovsBlueSelectedNotification,
              borderRadius: 1,
              width: "100%",
            }
          : {})}
      >
        <Box display="flex">
          <Avatar
            variant="square"
            alt="Vehicle Preview"
            src={vehiclePic || ""}
            sx={{ borderRadius: "4px", width: 80, height: 55 }}
          />
        </Box>
        <Box display="flex" flexDirection="column" ml={2}>
          <Box display="flex" alignItems="center" gap={0.8}>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: 700, fontSize: "16px" }}
            >
              {vehicleName}
            </Typography>
            {hasVehicleChanged && <NotificationIcon />}
          </Box>

          <Typography variant="overline" sx={subtitleCustomStyle}>
            VEHICLE TYPE
          </Typography>
          <Typography variant="caption" sx={carParamCustomStyle}>
            {vehicleType}
          </Typography>

          {vehiclePlateNo && (
            <Box display="flex" flexDirection="column" mt={1}>
              <Typography variant="overline" sx={subtitleCustomStyle}>
                PLATE NUMBER
              </Typography>
              <Typography variant="caption" sx={carParamCustomStyle}>
                {vehiclePlateNo}
              </Typography>
            </Box>
          )}

          {hasVehicleChanged && (
            <Box display="flex" flexDirection="column" mt={1}>
              <Typography variant="caption" sx={oldVehicleStyle}>
                Original:
              </Typography>
              <Typography
                variant="caption"
                sx={{ ...oldVehicleStyle, textDecoration: "line-through" }}
              >
                {oldVehicle.vehicleType.typeName}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Card>
  );
}

export default VehicleCard;
