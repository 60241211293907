import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

type AlertDialogProps = {
  open: boolean;
  onClose: () => void;
  onAccept: () => void;
  title: string;
  closeButtonText: string;
  acceptButtonText: string;
  content: string;
};

function AlertDialog(props: AlertDialogProps) {
  const {
    open,
    onClose,
    onAccept,
    title,
    content,
    closeButtonText,
    acceptButtonText,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      sx={{
        "& .MuiPaper-root": {
          padding: "30px",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: 1.2,
          letterSpacing: "0.15px",
          padding: 0,
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          padding: 0,
          marginTop: "24px",
          marginBottom: "32px",
        }}
      >
        <DialogContentText
          sx={{
            fontSize: "14px",
            lineHeight: 1.43,
            padding: 0,
            margin: 0,
          }}
          id="alert-dialog-description"
        >
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: 0 }}>
        <Button onClick={onClose} color="primary">
          {closeButtonText}
        </Button>
        <Button
          onClick={onAccept}
          color="primary"
          variant="contained"
          autoFocus
          disableElevation
        >
          {acceptButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog;
