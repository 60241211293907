import React from "react";
import moment from "moment-timezone";

import {
  Box,
  Drawer,
  useTheme,
  Typography,
  IconButton,
  Divider,
  useMediaQuery,
} from "@mui/material";

import {
  rideStatusBgDone,
  successGreen,
  white,
  granite,
} from "globals/design-system/colors";
import { currency } from "globals/utils/helpers";
import { OperatorRoute } from "types";
import { RoundChip } from "components/ui";
import { ArrowLeftIcon } from "globals/design-system/icons";
import DriverPayoutItem from "../DriverPayoutItem";
import ItemContent from "./ItemContent";

const BOTTOM_TOTAL_DISPLAY_WIDTH = 78;
const HEADER_WIDTH = 110;

type DriverPayoutDetailsDrawerProps = {
  open: boolean;
  operatorRoutes: OperatorRoute[];
  totalAmt: number;
  onBackClick: () => void;
  payoutCreatedAt: moment.Moment;
};

function DriverPayoutDetailsDrawer(props: DriverPayoutDetailsDrawerProps) {
  const { open, operatorRoutes, totalAmt, onBackClick, payoutCreatedAt } =
    props;

  const theme = useTheme();

  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  return (
    <Drawer
      anchor="right"
      open={open}
      sx={{
        "& .MuiDrawer-paper": {
          height: "100vh",
          width: "100vw",
          margin: 0,
          padding: `0 ${theme.spacing(2)}`,
        },
      }}
    >
      <Box display="flex" flexDirection="column">
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            zIndex: theme.zIndex.drawer + 1,
            [theme.breakpoints.down("lg")]: {
              borderBottom: `none`,
            },
          }}
          flexDirection="column"
          justifyContent="space-between"
          display="flex"
        >
          <Box>
            <IconButton
              edge="start"
              color="primary"
              onClick={onBackClick}
              size="large"
            >
              <ArrowLeftIcon color={granite} />
            </IconButton>
          </Box>

          {/* Header */}
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
            width={isMobileView ? undefined : "600px"}
            alignSelf={isMobileView ? undefined : "center"}
          >
            <Box display="flex" flex="1">
              <ItemContent
                header="Payment Date"
                content={moment(payoutCreatedAt).format("MM/DD/YY")}
                contentVariant="body2"
                contentStyles={{ fontWeight: 500 }}
              />
              <ItemContent header="Trips" content={operatorRoutes.length} />
            </Box>
            <ItemContent
              header="status"
              content={
                <RoundChip
                  inputProps={{ style: { cursor: "pointer" } }}
                  label="PAID"
                  backgroundColor={rideStatusBgDone}
                  color={successGreen}
                />
              }
            />
          </Box>
        </Box>

        {/* Route Items */}
        <Box
          maxHeight={`calc(100vh - ${HEADER_WIDTH}px - ${BOTTOM_TOTAL_DISPLAY_WIDTH}px)`}
        >
          {operatorRoutes.map((route, index) => (
            <Box
              key={index}
              maxWidth={theme.breakpoints.values.sm}
              mx="auto"
              mb={1}
            >
              <DriverPayoutItem mode="routePayout" route={route} />
            </Box>
          ))}
          <Box height={`${BOTTOM_TOTAL_DISPLAY_WIDTH}px`} />
        </Box>

        {/* Totals */}
        <Box
          position="fixed"
          bgcolor={white}
          bottom="0"
          left="0"
          right="0"
          height={`${BOTTOM_TOTAL_DISPLAY_WIDTH}px`}
          maxWidth="600px"
          mx="auto"
        >
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height="100%"
            mx={3}
          >
            <Typography>Totals</Typography>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {currency(totalAmt)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}

export default DriverPayoutDetailsDrawer;
