import React from "react";

import { Avatar, Box, Chip, ChipProps, Typography } from "@mui/material";

import {
  black,
  grayDark,
  grayLight,
  white,
} from "globals/design-system/colors";
import { ChildSeatVariant } from "types";

const { RearFacing, ForwardFacing, Booster } = ChildSeatVariant;

type ChildSeatChipProps = {
  variant: ChildSeatVariant;
  quantity?: number;
  ChipProps?: ChipProps;
  isOld?: boolean;
};

const CHILD_SEAT_LABELS = {
  [RearFacing]: "Rear-Facing Seat",
  [ForwardFacing]: "Forward-Facing Seat",
  [Booster]: "Booster Seat",
};

function ChildSeatChip(props: ChildSeatChipProps) {
  const { variant, quantity, ChipProps, isOld } = props;

  if (!quantity) {
    return null;
  }

  return (
    <Chip
      {...ChipProps}
      component="div"
      label={
        <Box display="flex" alignItems="center" gap={0.5}>
          <Typography
            variant="overline"
            color={black}
            style={{
              textDecorationLine: isOld ? "line-through" : undefined,
            }}
          >
            {CHILD_SEAT_LABELS[variant]}
          </Typography>
          <Avatar sx={{ width: 16, height: 16, backgroundColor: grayDark }}>
            <Typography color={white} fontSize="10px">
              {quantity}
            </Typography>
          </Avatar>
        </Box>
      }
      size="small"
      sx={{
        mt: 0.5,
        mr: 0.5,
        backgroundColor: grayLight,
        borderRadius: 4,
      }}
    />
  );
}

export default ChildSeatChip;
