/**
 * @file graphql/driver.ts
 * queries/mutations for Driver.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_DRIVER = gql`
  query Driver($id: ID!) {
    node(id: $id) {
      id
      ... on Driver {
        id
        firstName
        lastName
        mobilePhone
        homePhone
        homeAddress
        email
        licenseNumber
        licenseClass
        licenseState
        licenseExpirationDate
        note
        driverProfilePhoto
        operator {
          id
          name
          nameSlug
          plan
          moovsMarketApps {
            id
            driverApp {
              id
              active
            }
          }
        }
      }
    }
  }
`;
