/**
 * @file HeaderBlock.tsx
 * Header of driver dispatch view
 *
 * components:
 *  HeaderBlock
 */

import React from "react";
import moment from "moment-timezone";
import first from "lodash/first";

import { Box, Divider } from "@mui/material";

import {
  ClockIcon,
  EstimationIcon,
  TripIcon,
  UserIcon,
} from "globals/design-system/icons";
import { Trip } from "types";
import { convertMinutesToHoursMinutes } from "globals/utils/helpers";
import { IconInfoSegment } from "components/ui";
import TripDateBlock from "components/shared/TripDateBlock";
import TripTimeBlock from "components/shared/TripTimeBlock";
import AmountDueBlock from "components/shared/AmountDueBlock";

type HeaderBlockProps = {
  trip: Trip;
  tripType: string;
  amountDue: number;
};

function HeaderBlock(props: HeaderBlockProps) {
  const { trip, tripType, amountDue } = props;

  const pickUpStop = first(trip.stops);
  const pickUpOriginalTime = moment
    .utc(pickUpStop.originalDateTime)
    .format("h:mm A");
  const pickUpDate = moment.utc(pickUpStop.dateTime).format("M/DD/YY");
  const pickUpTime = moment.utc(pickUpStop.dateTime).format("h:mm A");
  const isDateTimeSame =
    moment
      .utc(pickUpStop.dateTime)
      .diff(moment.utc(pickUpStop.originalDateTime), "minutes") === 0;

  return (
    <Box>
      <Box display="flex" flexDirection="row" mb={2}>
        <TripDateBlock tripDate={pickUpDate} />
        <TripTimeBlock
          tripTime={pickUpTime}
          tripOriginalTime={pickUpOriginalTime}
          isDateTimeSame={isDateTimeSame}
        />
        <AmountDueBlock amountDue={amountDue} />
      </Box>
      <Box my={2}>
        <Divider />
      </Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <Box mr={1} mb={1}>
          <IconInfoSegment
            label="Passenger count"
            value={trip.totalGroupSize}
            icon={UserIcon}
          />
        </Box>
        <Box mr={1} mb={1}>
          <IconInfoSegment label="Trip Type" value={tripType} icon={TripIcon} />
        </Box>
        <Box mr={1} mb={1}>
          <IconInfoSegment
            label={
              trip.useTotalDuration ? "total duration" : "estimated duration"
            }
            value={
              trip.useTotalDuration ? (
                convertMinutesToHoursMinutes(trip.totalDuration)
              ) : (
                <Box display="flex" alignItems="center">
                  <Box mr={0.5}>
                    {convertMinutesToHoursMinutes(trip.estimatedDuration)}
                  </Box>
                  <EstimationIcon />
                </Box>
              )
            }
            icon={ClockIcon}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default HeaderBlock;
