import React from "react";

import { Box, Typography } from "@mui/material";
import { grayDark } from "globals/design-system/colors";
import FlightIcon from "globals/design-system/icons/info/FlightIcon";

type TripTimeBlockProps = {
  tripTime: string;
  tripOriginalTime: string | null;
  flightOffset?: number;
  isDateTimeSame?: boolean;
};

const TripTimeBlock = (props: TripTimeBlockProps) => {
  const { flightOffset, isDateTimeSame, tripTime, tripOriginalTime } = props;
  return (
    <Box mr={2} display="flex" flexDirection="column" alignSelf="center">
      <Typography variant="overline" style={{ color: grayDark }}>
        Time
      </Typography>
      <Box>
        {flightOffset && !isDateTimeSame ? (
          <>
            <Typography
              variant="body1"
              style={{
                fontWeight: "bold",
                textDecorationLine: "line-through",
                color: grayDark,
              }}
            >
              {tripOriginalTime}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography
                variant="body1"
                style={{ fontWeight: "bold", marginRight: 5 }}
              >
                {tripTime}
              </Typography>
              <FlightIcon size="small" />
            </Box>
          </>
        ) : (
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            {tripTime}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default TripTimeBlock;
