import React, { useState } from "react";

import { MobileDateTimePicker, MobileDateTimePickerProps } from "@mui/lab";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { CalendarIcon } from "../../globals/design-system/icons";

type MoovsDateTimePickerProps = {
  onAccept: (date: any) => void;
  renderInputProps?: TextFieldProps;
  clearable?: boolean;
} & Omit<
  MobileDateTimePickerProps,
  "onChange" | "renderInput" | "onClose" | "InputProps" | "clearable" | "open"
>;

function MoovsDateTimePicker(props: MoovsDateTimePickerProps) {
  const {
    renderInputProps = {},
    clearable = false,
    onAccept,
    ...otherProps
  } = props;

  // state
  const [openDateTimePicker, setOpenDateTimePicker] = useState(false);

  // event handlers
  const handleClosePicker = () => setOpenDateTimePicker(false);
  const handleOpenPicker = () => setOpenDateTimePicker(true);

  const handleAccept = (date) => {
    onAccept(date);
    handleClosePicker();
  };

  return (
    <MobileDateTimePicker
      {...otherProps}
      open={openDateTimePicker}
      clearable={clearable}
      onChange={() => {}}
      onAccept={handleAccept}
      onClose={handleClosePicker}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CalendarIcon />
          </InputAdornment>
        ),
        style: { cursor: "pointer" },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onClick={handleOpenPicker}
          variant="outlined"
          fullWidth
          inputProps={{
            ...params.inputProps,
            style: { cursor: "pointer" },
          }}
          {...renderInputProps}
        />
      )}
    />
  );
}
export default MoovsDateTimePicker;
