/**
 * @file useMobileOsDetector.tsx
 *
 * Simple, somewhat hacky way of determining mobile device.
 *
 * reference: https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
 */

export enum Device {
  Android,
  iOS,
}

export const useMobileOsDetector = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return Device.Android;
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return Device.iOS;
  }
};
