import React from "react";
import { Typography, Box } from "@mui/material";

import { Driver } from "types";
import { granite } from "globals/design-system/colors";
import theme from "globals/design-system/theme";
import { CircleAvatar } from "components/ui";

type DriverHeaderProps = {
  driver: Driver;
};

function DriverHeader(props: DriverHeaderProps) {
  const { driver } = props;

  return (
    <Box
      display="flex"
      flexDirection="row"
      height="60px"
      alignItems="center"
      justifyContent="center"
      bgcolor={theme.palette.background.paper}
    >
      <CircleAvatar
        alt="driver"
        size="medium"
        placeholder={[driver.firstName, driver.lastName]}
        src={driver?.driverProfilePhoto}
      />
      <Typography
        style={{ marginLeft: 8, letterSpacing: "-0.25px", color: granite }}
      >{`${driver.firstName} ${driver.lastName}`}</Typography>
    </Box>
  );
}

export default DriverHeader;
