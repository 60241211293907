import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { Typography } from "@mui/material";

type GreetingSignTextProps = {
  namePartFontSize: number;
  namePartWidth: number;
  passengerName: string;
  setNamePartWidth: Dispatch<SetStateAction<number>>;
  finalFontSize: number;
  setPaddingTop?: boolean;
  setPaddingBottom?: boolean;
};

const GreetingSignText = (props: GreetingSignTextProps) => {
  const {
    namePartFontSize,
    namePartWidth,
    passengerName,
    setNamePartWidth,
    setPaddingTop,
    setPaddingBottom,
    finalFontSize,
  } = props;

  const namePartRef = useRef(null);

  useEffect(() => {
    setNamePartWidth(namePartRef?.current?.offsetWidth);
  });

  return (
    <Typography
      ref={namePartRef}
      sx={{
        fontSize: `${
          finalFontSize !== Infinity ? finalFontSize : namePartFontSize
        }vw`,
        whiteSpace: "nowrap",
        Width: { width: namePartWidth },
        ...(setPaddingTop && { paddingTop: "24px" }),
        ...(setPaddingBottom && { paddingBottom: "24px" }),
      }}
    >
      {passengerName}
    </Typography>
  );
};

export default GreetingSignText;
