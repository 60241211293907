/**
 * @file RoundChip.tsx
 * Restyling of Chip component to match designs.
 */
import React, { ComponentType } from "react";

import { Chip, ChipProps, IconProps } from "@mui/material";
import {
  requestStatusLabelColors,
  invoiceStatusLabelColors,
} from "globals/design-system/colors";

type TextTransform =
  | "-moz-initial"
  | "inherit"
  | "initial"
  | "revert"
  | "unset"
  | "none"
  | "full-width"
  | "capitalize"
  | "full-size-kana"
  | "lowercase"
  | "uppercase";

type RequestStatusVariants = "NEW_QUOTE" | "DRAFT" | "EMAIL_SENT";
type InvoiceStatusVariants = "PAID" | "NOT_PAID" | "PARTIAL";

export type RoundChipProps = {
  variant?: RequestStatusVariants | InvoiceStatusVariants;
  label?: string;
  icon?: ComponentType<IconProps>;
  color?: string;
  backgroundColor?: string;
  textTransform?: TextTransform;
  iconProps?: IconProps;
  inputProps?: ChipProps;
};

function RoundChip(props: RoundChipProps) {
  const {
    label,
    icon,
    color,
    backgroundColor,
    iconProps,
    inputProps,
    variant,
    textTransform,
  } = props;

  const Icon = icon;

  const requestStatusMap = {
    NEW_QUOTE: "NEW",
    DRAFT: "DRAFT",
    EMAIL_SENT: "SENT",
  };

  if (requestStatusMap.hasOwnProperty(variant)) {
    return (
      <RoundChip
        label={requestStatusMap[variant]}
        {...requestStatusLabelColors[variant]}
        inputProps={inputProps}
      />
    );
  }

  const invoiceStatusMap = {
    PAID: "PAID",
    NOT_PAID: "NOT PAID",
    PARTIAL: "PARTIAL",
  };

  if (invoiceStatusMap.hasOwnProperty(variant)) {
    return (
      <RoundChip
        label={invoiceStatusMap[variant]}
        {...invoiceStatusLabelColors[variant]}
        inputProps={inputProps}
      />
    );
  }

  return (
    <Chip
      sx={{
        borderRadius: "50px",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: 12,
        ...(color && { color }),
        ...(backgroundColor && { backgroundColor }),

        "& span": {
          ...(textTransform && { textTransform }),
        },
      }}
      size="small"
      label={label}
      icon={icon && <Icon fontSize="small" {...iconProps} style={{ color }} />}
      {...inputProps}
    />
  );
}

export default RoundChip;
