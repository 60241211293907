import first from "lodash/first";
import last from "lodash/last";
import moment from "moment";

import { OperatorRoute } from "../../../types";

// get scheduled end date/time from stop (works with estimate and non-estimate)
export const getEndDateTimeForRouteFeedback = (
  operatorRoute: OperatorRoute
): string => {
  const {
    trip: { stops, estimatedDuration },
  } = operatorRoute;

  const { dateTime: scheduledStartDateTime } = first(stops);
  const { dateTime: scheduledEndDateTime } = last(stops);

  const customerEndDateTime = scheduledEndDateTime
    ? scheduledEndDateTime
    : moment
        .utc(scheduledStartDateTime)
        .add(estimatedDuration, "minutes")
        .toISOString();

  return customerEndDateTime;
};
