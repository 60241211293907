/**
 * @file Layout.tsx
 * Somewhat unnecessary at this point.
 * But laying foundation for future pages.
 *
 * reactComponents:
 *   Layout
 */

import React, { ReactNode } from "react";

import { styled } from "@mui/material/styles";

import theme from "./globals/design-system/theme";

const RootDiv = styled("div")({
  paddingBottom: theme.breakpoints.down("sm") ? theme.spacing(1) : 0,
});

type LayoutProps = {
  children: ReactNode;
};

function Layout(props: LayoutProps) {
  const { children } = props;

  return <RootDiv>{children}</RootDiv>;
}

export default Layout;
