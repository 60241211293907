import React from "react";

import { Box, Typography } from "@mui/material";

import { grayDark } from "globals/design-system/colors";

type TripDateBlockProps = {
  tripDate: string;
};
const TripDateBlock = (props: TripDateBlockProps) => {
  const { tripDate } = props;
  return (
    <Box mr={2} display="flex" flexDirection="column" alignSelf="center">
      <Typography variant="overline" style={{ color: grayDark }}>
        Trip Date
      </Typography>
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        {tripDate}
      </Typography>
    </Box>
  );
};

export default TripDateBlock;
