import React from "react";

import {
  Box,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { granite, errorRed, orange } from "globals/design-system/colors";

import { ClockIcon, WarningIcon } from "globals/design-system/icons";

type PrioritizedFlightInfoType = {
  transitStopType: "origin" | "destination";
  primaryText: string;
  secondaryText: string;
  primaryProps?: TypographyProps;
  secondaryProps?: TypographyProps;
  isTime?: boolean;
  strikethroughAll?: boolean;
};

function PrioritizedFlightInfo(props: PrioritizedFlightInfoType) {
  const {
    primaryText,
    secondaryText,
    primaryProps,
    secondaryProps,
    transitStopType,
    isTime,
    strikethroughAll,
  } = props;

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  const bothTextShown =
    !!primaryText && !!secondaryText && primaryText !== secondaryText;
  const alignRight = isMobileView && transitStopType === "destination";

  return (
    <Box>
      <Box position="relative">
        {bothTextShown && transitStopType === "destination" && (
          <Box position="absolute" left="-30px" {...(isTime && { top: "3px" })}>
            {isTime ? (
              <ClockIcon color={orange} />
            ) : (
              <WarningIcon color={errorRed} />
            )}
          </Box>
        )}
        <Typography
          variant="h6"
          {...primaryProps}
          {...(strikethroughAll && {
            style: {
              color: granite,
              textDecoration: "line-through",
              ...primaryProps?.style,
            },
          })}
          {...(alignRight && {
            align: "right",
          })}
        >
          {primaryText || secondaryText || "-"}
        </Typography>
      </Box>
      {bothTextShown && (
        <Typography
          variant="body2"
          {...secondaryProps}
          style={{
            color: granite,
            textDecoration: "line-through",
            ...secondaryProps?.style,
          }}
          {...(alignRight && { align: "right" })}
        >
          {secondaryText}
        </Typography>
      )}
    </Box>
  );
}

export default PrioritizedFlightInfo;
