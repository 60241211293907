import React from "react";

import { Typography, Box } from "@mui/material";

import noEarningsIcon from "globals/design-system/illustrations/noEarningsIcon.svg";

function EmptyEarningsList(props) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mt={2} textAlign="center">
        <img src={noEarningsIcon} alt="Empty List" />
        <Box mt={2} px={2}>
          <Typography variant="h3">
            You don’t have any earnings to show here yet
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default EmptyEarningsList;
