
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "FarmAffiliate": [
      "ExternalOperator",
      "MoovsNetworkOperator",
      "GriddnetOperator",
      "MoovsAiOperator"
    ],
    "MetadataUnion": [
      "TripChangeMetadata"
    ],
    "Node": [
      "Billing",
      "CancellationPolicy",
      "Comment",
      "Company",
      "Contact",
      "Driver",
      "DriverAvailability",
      "EmailLog",
      "ExternalOperator",
      "GriddnetOperator",
      "InsurancePolicy",
      "Invoice",
      "MoovsAiOperator",
      "MoovsNetworkOperator",
      "Operator",
      "OperatorRoute",
      "Payment",
      "PaymentMethod",
      "PaymentRefund",
      "Request",
      "Route",
      "ShuttleRoute",
      "Stop",
      "SubPayment",
      "SubRefund",
      "TeamMember",
      "TrackedFlight",
      "Transaction",
      "Trip",
      "User",
      "UserAccessPermission",
      "Vehicle",
      "VehicleChildSeat",
      "VehicleFeature",
      "VehiclePhoto"
    ]
  }
};
      export default result;
    