/**
 * @file FlightInfoCard.tsx
 * Renders info about a future or upcoming flight.
 * Flight info comes from flight aware.
 *
 * components:
 *  FlightInfoCard
 */

import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Fade,
  Collapse,
  IconButton,
} from "@mui/material";

import {
  granite,
  grayLight,
  grayMedium,
  moovsBlue,
} from "globals/design-system/colors";
import {
  CollapseIcon,
  MaximizeFullIcon,
  OpenIcon,
} from "globals/design-system/icons";
import { useAnalytics } from "globals/hooks/useAnalytics";
import {
  FlightDetails,
  DivertedFlightInfo,
  AirportInfo,
  FlightProgressDisplay,
} from "./components";
import { TrackedFlight } from "types";
import moment from "moment-timezone";
import CompactFlightInfoCard from "./components/CompactFlightInfoCard";

export type FlightInfoCardContentProps = {
  refreshFlightProps: {
    isRefreshingFlight?: boolean;
    onClickRefreshFlight?: () => void;
  };
  airlineHeader: string;
  trackedFlight: TrackedFlight;
  flightNumberCustomStyle: {
    color: string;
    fontWeight: number;
  };
};

type FlightInfoCardProps = {
  trackedFlight: TrackedFlight;
  flightType: "origin" | "destination";
  isOutdatedFlight?: boolean;
};

function FlightInfoCollapsibleCard(props: FlightInfoCardProps) {
  const { trackedFlight, flightType, isOutdatedFlight } = props;

  // state
  const [isExpanded, setIsExpanded] = useState(false);

  // hooks
  const { track } = useAnalytics();

  const { origin, destination, flightStatus, faFlightId, updatedAt } =
    trackedFlight;

  // event handlers
  const handleFlightAwareLink = (e: React.MouseEvent) => {
    e.stopPropagation();
    track("reservations_flightAwareOpened");

    window
      .open(`https://flightaware.com/live/flight/id/${faFlightId}`, "_blank")
      .focus();
  };

  return (
    <Box px={2} py={1} borderRadius={4} my={1}>
      <Box p={2} bgcolor={grayLight} borderRadius={1}>
        <Box
          display="flex"
          alignItems="flexStart"
          justifyContent="space-between"
          data-testid="compact-flight-info"
          sx={{
            mb: isExpanded ? -3 : 0,
            transition: "margin-bottom 0.25s linear",
          }}
        >
          <CompactFlightInfoCard
            transitStopType={flightType}
            trackedFlight={trackedFlight}
            isExpanded={isExpanded}
            isOutdatedFlight={isOutdatedFlight}
          />
          <Box width="36px" pl={0.5}>
            <IconButton
              disabled={isOutdatedFlight}
              style={{
                marginTop: "-4px",
                marginRight: "-8px",
              }}
              onClick={() => setIsExpanded(!isExpanded)}
              data-testid="expand-collapse-trigger-button"
            >
              {isExpanded ? (
                <MaximizeFullIcon color={granite} />
              ) : (
                <CollapseIcon color={isOutdatedFlight ? grayMedium : granite} />
              )}
            </IconButton>
          </Box>
        </Box>

        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <Fade in={isExpanded}>
            <Box display="flex" flexDirection="column" textAlign="right">
              <Typography variant="h6">Updated:</Typography>
              <Typography variant="caption">
                {moment(updatedAt).calendar()}
              </Typography>
            </Box>
          </Fade>

          <FlightProgressDisplay flightStatus={flightStatus} />

          <Box display="flex" justifyContent="space-between">
            <Box maxWidth="104px">
              <AirportInfo
                airportCode={origin.airport.iataCode || "-"}
                airportName={origin.airport.airportName || ""}
                transitStopType="origin"
              />
            </Box>
            <Box maxWidth="104px">
              <AirportInfo
                airportCode={destination.airport.iataCode || "-"}
                airportName={destination.airport.airportName || ""}
                transitStopType="destination"
              />
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between">
            <FlightDetails
              transitStop={origin}
              transitStopType="origin"
              flightStatus={flightStatus}
            />
            <FlightDetails
              transitStop={destination}
              transitStopType="destination"
              flightStatus={flightStatus}
            />
          </Box>

          {faFlightId && (
            <Box mt={2}>
              <Button
                variant="outlined"
                fullWidth
                endIcon={<OpenIcon size="small" color={moovsBlue} />}
                onClick={handleFlightAwareLink}
              >
                <Typography variant="body2" fontWeight={500}>
                  FlightAware
                </Typography>
              </Button>
            </Box>
          )}
        </Collapse>

        {flightStatus === "DIVERTED" && (
          <DivertedFlightInfo flightNumber={trackedFlight.flightNumber} />
        )}
      </Box>
    </Box>
  );
}

export default FlightInfoCollapsibleCard;
