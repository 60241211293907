import { useQuery } from "@apollo/client";
import { LOAD_OPERATOR_ROUTE_QUERY } from "globals/graphql";
import { OperatorRoute } from "types";
import { useSnackbar } from "./useSnackbar";

const useOperatorRoute = (
  operatorRouteId: string,
  isPolling?: { pollingEnabled: boolean }
) => {
  const snackbar = useSnackbar();

  const {
    data: operatorRouteData,
    loading: operatorRouteLoading,
    error: operatorRouteError,
    refetch: operatorRouteRefetch,
  } = useQuery<{ driverRoute: OperatorRoute }>(LOAD_OPERATOR_ROUTE_QUERY, {
    variables: {
      operatorRouteId,
    },
    onError(error) {
      console.error(error);
      snackbar.error(
        "Driver is not assigned to this trip. Please check with your operator"
      );
    },
    skip: !operatorRouteId,
    fetchPolicy: "cache-and-network",
    pollInterval: isPolling?.pollingEnabled ? 60 * 1000 : undefined, // every minute
  });

  return {
    operatorRouteData,
    operatorRouteLoading,
    operatorRouteError,
    operatorRouteRefetch,
  };
};

export default useOperatorRoute;
