/**
 * @file SquareChip.tsx
 * Restyling of Chip component to match designs.
 *
 */
import React from "react";

import { Chip, ChipProps } from "@mui/material";

type SquareChipProps = {
  label?: any;
  icon?: any;
  color?: string;
  backgroundColor?: string;
  iconProps?: any;
  variant?: "dispatchAlert";
  inputProps?: ChipProps;
};

function SquareChip(props: SquareChipProps) {
  const {
    label,
    icon,
    color,
    backgroundColor,
    iconProps,
    variant,
    inputProps,
  } = props;

  const Icon = icon;

  if (variant === "dispatchAlert") {
    return (
      <SquareChip
        backgroundColor={backgroundColor}
        color={color}
        label={label}
        icon={icon}
        iconProps={{ color }}
      />
    );
  }

  return (
    <Chip
      sx={{
        borderRadius: "6px",
        padding: "18px 6px",
        fontSize: "14px",
        fontWeight: 500,
        ...(color && { color }),
        ...(backgroundColor && { backgroundColor }),
      }}
      size="medium"
      label={label}
      icon={icon && <Icon fontSize="small" style={{ color }} {...iconProps} />}
      {...inputProps}
    />
  );
}

export default SquareChip;
