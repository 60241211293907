import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { LOAD_OPERATOR_ROUTES_QUERY } from "globals/graphql";
import { OperatorRoutesEdge } from "types";

const useNotification = (routeId?: string) => {
  const { driverId } = useParams<{
    driverId: string;
  }>();

  const { data: operatorRoutesData, loading } = useQuery<{
    operatorRoutes: {
      edges: OperatorRoutesEdge[];
    };
  }>(LOAD_OPERATOR_ROUTES_QUERY, {
    variables: {
      driverId,
      tabName: "UPCOMING",
    },
    fetchPolicy: "no-cache",
    skip: !driverId,
    pollInterval: 60 * 1000, // every minute
  });

  const operatorRoutes = (operatorRoutesData?.operatorRoutes?.edges || []).map(
    ({ node }) => node
  );

  const operatorRouteWithNotifications = operatorRoutes.filter(
    (route) => route?.trip?.notification !== null
  );
  const routeIdsWithNotification = operatorRouteWithNotifications.map(
    (route) => route.id
  );

  const notification = operatorRouteWithNotifications.find(
    (route) => routeId && route.id === routeId
  )?.trip?.notification;

  return {
    routeIdsWithNotification,
    notification,
    loading,
  };
};

export default useNotification;
