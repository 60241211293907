import React, { useState } from "react";
import moment from "moment-timezone";

import { Box, Card, CardActionArea, Divider } from "@mui/material";

import { RoundChip } from "components/ui";
import {
  rideStatusBgDone,
  successGreen,
} from "../../../../globals/design-system/colors";
import { OperatorRoute, DriverPayable } from "types";
import { ItemContent, DriverPayoutDetailsDrawer } from "./components";

type DriverPayoutItemProps =
  | { mode: "driverPayout"; payout: DriverPayable; route?: never }
  | { mode: "routePayout"; payout?: never; route: OperatorRoute };

function DriverPayoutItem(props: DriverPayoutItemProps) {
  const { mode, payout, route } = props;

  const { flatRateAmt, gratuityAmt, hourlyAmt, totalAmt } =
    mode === "driverPayout" ? payout : route;

  // state
  const [openPayoutDetails, setOpenPayoutDetails] = useState(false);

  // event handlers
  const handleDriverPayoutItemOpen = () => {
    setOpenPayoutDetails(true);
  };

  const handleDriverPayoutItemClose = () => {
    setOpenPayoutDetails(false);
  };

  const modeMap = (mode: "driverPayout" | "routePayout") =>
    ({
      driverPayout: {
        topLeftColumnHeader: "Payment Date",
        topLeftColumnValue: payout?.payoutCreatedAt,
        topMiddleColumnHeader: "Trips",
        topMiddleColumnValue: payout?.routesCount,
        showPaidChip: true,
        renderDriverPayoutDetailsDrawer: true,
        onItemClick: handleDriverPayoutItemOpen,
      },
      routePayout: {
        topLeftColumnHeader: "Trip Date",
        topLeftColumnValue: route?.trip?.stops?.[0]?.dateTime,
        topMiddleColumnHeader: "Conf. No.",
        topMiddleColumnValue: route?.completeOrderNumber,
        showPaidChip: false,
        renderDriverPayoutDetailsDrawer: false,
        // onItemClick: handleOperatorRouteClick, // removed for now - in future, these items would open up DispatchPage view of the route
      },
    }[mode]);

  const {
    topLeftColumnHeader,
    topLeftColumnValue,
    topMiddleColumnHeader,
    topMiddleColumnValue,
    showPaidChip,
    renderDriverPayoutDetailsDrawer,
    onItemClick,
  } = modeMap(mode);

  return (
    <>
      <Card variant="outlined">
        <CardActionArea
          disableRipple
          onClick={onItemClick}
          {...(!onItemClick && { disabled: true })}
        >
          <Box
            display="flex"
            flexDirection="row"
            flex=".4"
            py={2}
            px={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" flex="1">
              <ItemContent
                header={topLeftColumnHeader}
                content={moment.utc(topLeftColumnValue).format("MM/DD/YY")}
                contentStyles={{
                  fontWeight: mode === "driverPayout" ? 500 : undefined,
                }}
              />
              <ItemContent
                header={topMiddleColumnHeader}
                content={topMiddleColumnValue}
              />
            </Box>
            {showPaidChip && (
              <Box>
                <RoundChip
                  inputProps={{ style: { cursor: "pointer" } }}
                  label="PAID"
                  backgroundColor={rideStatusBgDone}
                  color={successGreen}
                />
              </Box>
            )}
          </Box>

          <Box mx={2}>
            <Divider />
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            flex=".4"
            py={2}
            px={2}
            alignItems="center"
          >
            <ItemContent
              header="Flat Rate"
              content={flatRateAmt || "-"}
              type="currency"
              contentVariant="h6"
            />
            <ItemContent
              header="Hourly"
              content={hourlyAmt || "-"}
              type="currency"
              contentVariant="h6"
            />
            <ItemContent
              header="Gratuity"
              content={gratuityAmt || "-"}
              type="currency"
              contentVariant="h6"
            />
            <ItemContent
              header="Total"
              content={totalAmt}
              type="currency"
              contentVariant="h6"
              contentStyles={{ fontWeight: "bold" }}
            />
          </Box>
        </CardActionArea>
      </Card>
      {renderDriverPayoutDetailsDrawer && (
        <DriverPayoutDetailsDrawer
          open={openPayoutDetails}
          onBackClick={handleDriverPayoutItemClose}
          operatorRoutes={payout?.operatorRoutes}
          totalAmt={totalAmt}
          payoutCreatedAt={payout?.payoutCreatedAt}
        />
      )}
    </>
  );
}

export default DriverPayoutItem;
