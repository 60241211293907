/**
 * @file CircleButton.tsx
 * Circle extension of material ui button
 *
 * components:
 *  CircleButton
 */

import React from "react";

import { Button, ButtonProps } from "@mui/material";

function CircleButton(props: ButtonProps | any) {
  return (
    <Button
      disableElevation
      color="primary"
      variant="contained"
      {...props}
      sx={{
        minWidth: 48,
        minHeight: 48,
        borderRadius: "50%",
        padding: 0,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
      }}
    ></Button>
  );
}

export default CircleButton;
