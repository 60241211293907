import React from "react";

import {
  Box,
  Typography,
  TypographyVariant,
  useMediaQuery,
} from "@mui/material";

import { TypographyStyle } from "@mui/material/styles";
import { currency } from "globals/utils/helpers";

type ItemContentProps = {
  header: string;
  content: any;
  headerStyles?: TypographyStyle;
  contentStyles?: TypographyStyle;
  flex?: string;
  type?: "currency";
  headerVariant?: TypographyVariant;
  contentVariant?: TypographyVariant;
};

function ItemContent(props: ItemContentProps) {
  const {
    header,
    content,
    headerStyles,
    contentStyles,
    headerVariant,
    contentVariant,
    type,
  } = props;

  const iPhone5View = useMediaQuery("(max-width:350px)", {
    noSsr: true,
  });

  return (
    <Box
      display="flex"
      flexShrink={0}
      flexDirection="column"
      mr={iPhone5View ? 2 : 3}
    >
      <Box mb="2px">
        <Typography variant={headerVariant || "overline"} style={headerStyles}>
          {header}
        </Typography>
      </Box>
      <Typography variant={contentVariant || "body2"} style={contentStyles}>
        {type === "currency" && typeof content === "number"
          ? currency(content)
          : content}
      </Typography>
    </Box>
  );
}

export default ItemContent;
