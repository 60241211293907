import React, { ReactChild } from "react";

import { Box, Typography } from "@mui/material";
import { grayLight, grayDark } from "globals/design-system/colors";
import NotificationIcon from "globals/design-system/icons/info/NotificationIcon";

type IconInfoSegmentProps = {
  label: string;
  value: string | ReactChild;
  icon?: any;
  subText?: string;
  subTextDecorationLine?: "line-through" | "none" | "underline";
  hasNotification?: boolean;
};

function IconInfoSegment(props: IconInfoSegmentProps) {
  const {
    label,
    value,
    icon,
    subText,
    subTextDecorationLine = "line-through",
    hasNotification,
  } = props;

  const Icon = icon;

  return (
    <Box
      key={label}
      display="flex"
      flexDirection="row"
      alignItems="center"
      mr={2}
    >
      <Box display="flex">
        {icon && (
          <Box
            sx={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              width: 26,
              height: 26,
              marginRight: "7px",
              backgroundColor: grayLight,
              borderRadius: "50%",
            }}
          >
            <Icon color={grayDark} size="small" />
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="column">
        <Box display="flex" gap={0.8} alignItems="center">
          <Typography
            variant="overline"
            sx={{
              color: grayDark,
              lineHeight: 1.5,
              height: 18,
              whiteSpace: "nowrap",
            }}
          >
            {label}
          </Typography>
          {hasNotification && <NotificationIcon />}
        </Box>
        <Typography
          variant="body2"
          sx={{
            lineHeight: 1.33,
            fontSize: 14,
            height: 16,
            fontWeight: 400,
            whiteSpace: "nowrap",
          }}
        >
          {value}
        </Typography>

        <Typography
          variant="body2"
          style={{
            fontWeight: 400,
            lineHeight: "20px",
            fontSize: "12px",
            textDecorationLine: subTextDecorationLine,
            color: grayDark,
          }}
        >
          {subText}
        </Typography>
      </Box>
    </Box>
  );
}

export default IconInfoSegment;
