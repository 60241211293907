import React from "react";

type PlaneIconProps = {
  color?: string;
  size?: "small";
};

const PlaneIcon = (props: PlaneIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.7438 18.8542L19.2912 18.3071C19.4395 18.1589 19.5463 17.9744 19.6011 17.7721C19.6558 17.5697 19.6566 17.3566 19.6032 17.1539L17.6563 9.76005L20.2306 7.18721C20.6533 6.78371 20.9203 6.24438 20.9848 5.66381C21.0493 5.08325 20.9073 4.49851 20.5835 4.01215C20.3815 3.72714 20.1196 3.48966 19.8161 3.31631C19.5127 3.14296 19.175 3.03791 18.8268 3.00851C18.4785 2.97911 18.128 3.02607 17.7998 3.1461C17.4715 3.26613 17.1735 3.45634 16.9265 3.70344L14.2617 6.36745L6.86507 4.42243C6.66211 4.36898 6.44868 4.36966 6.24606 4.42439C6.04344 4.47912 5.85872 4.58599 5.71033 4.73433L5.16297 5.28137C5.03397 5.41029 4.93607 5.56689 4.8767 5.73929C4.81734 5.91168 4.79807 6.09533 4.82036 6.27629C4.84266 6.45724 4.90593 6.63073 5.00537 6.78359C5.10481 6.93644 5.2378 7.06463 5.39424 7.15842L10.4421 10.1855L6.60099 14.5362L5.40064 14.5538C5.21733 14.5225 5.02924 14.5341 4.85121 14.5878C4.67317 14.6415 4.51006 14.7358 4.37473 14.8633L3.17598 15.9606C3.10656 16.0298 3.05521 16.115 3.02641 16.2087C2.99762 16.3024 2.99227 16.4018 3.01084 16.498C3.0294 16.5942 3.07131 16.6845 3.13289 16.7607C3.19447 16.837 3.27384 16.897 3.36403 16.9355L5.68072 18.2415L7.20117 20.6368C7.23993 20.7268 7.30015 20.806 7.37655 20.8674C7.45296 20.9288 7.54324 20.9706 7.63952 20.9892C7.7358 21.0077 7.83516 21.0024 7.92892 20.9737C8.02269 20.9451 8.10802 20.8939 8.17746 20.8248L9.22497 19.8267C9.37057 19.6895 9.47956 19.5182 9.54204 19.3282C9.60451 19.1383 9.61848 18.9357 9.58268 18.739L9.60188 17.5353L13.8367 13.5781L16.8656 18.623C16.9595 18.7794 17.0877 18.9123 17.2407 19.0117C17.3936 19.111 17.5672 19.1743 17.7483 19.1966C17.9294 19.2188 18.1131 19.1996 18.2856 19.1402C18.4581 19.0809 18.6148 18.9831 18.7438 18.8542Z"
          stroke={color || "#B3B3B3"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7438 18.8542L19.2912 18.3071C19.4395 18.1589 19.5463 17.9744 19.6011 17.7721C19.6558 17.5697 19.6566 17.3566 19.6032 17.1539L17.6563 9.76005L20.2306 7.18721C20.6533 6.78371 20.9203 6.24438 20.9848 5.66381C21.0493 5.08325 20.9073 4.49851 20.5835 4.01215C20.3815 3.72714 20.1196 3.48966 19.8161 3.31631C19.5127 3.14296 19.175 3.03791 18.8268 3.00851C18.4785 2.97911 18.128 3.02607 17.7998 3.1461C17.4715 3.26613 17.1735 3.45634 16.9265 3.70344L14.2617 6.36745L6.86507 4.42243C6.66211 4.36898 6.44868 4.36966 6.24606 4.42439C6.04344 4.47912 5.85872 4.58599 5.71033 4.73433L5.16297 5.28137C5.03397 5.41029 4.93607 5.56689 4.8767 5.73929C4.81734 5.91168 4.79807 6.09533 4.82036 6.27629C4.84266 6.45724 4.90593 6.63073 5.00537 6.78359C5.10481 6.93644 5.2378 7.06463 5.39424 7.15842L10.4421 10.1855L6.60099 14.5362L5.40064 14.5538C5.21733 14.5225 5.02924 14.5341 4.85121 14.5878C4.67317 14.6415 4.51006 14.7358 4.37473 14.8633L3.17598 15.9606C3.10656 16.0298 3.05521 16.115 3.02641 16.2087C2.99762 16.3024 2.99227 16.4018 3.01084 16.498C3.0294 16.5942 3.07131 16.6845 3.13289 16.7607C3.19447 16.837 3.27384 16.897 3.36403 16.9355L5.68072 18.2415L7.20117 20.6368C7.23993 20.7268 7.30015 20.806 7.37655 20.8674C7.45296 20.9288 7.54324 20.9706 7.63952 20.9892C7.7358 21.0077 7.83516 21.0024 7.92892 20.9737C8.02269 20.9451 8.10802 20.8939 8.17746 20.8248L9.22497 19.8267C9.37057 19.6895 9.47956 19.5182 9.54204 19.3282C9.60451 19.1383 9.61848 18.9357 9.58268 18.739L9.60188 17.5353L13.8367 13.5781L16.8656 18.623C16.9595 18.7794 17.0877 18.9123 17.2407 19.0117C17.3936 19.111 17.5672 19.1743 17.7483 19.1966C17.9294 19.2188 18.1131 19.1996 18.2856 19.1402C18.4581 19.0809 18.6148 18.9831 18.7438 18.8542Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlaneIcon;
