import React from "react";
import { Box, Typography } from "@mui/material";

import {
  grayDark,
  moovsBlue,
  moovsBlueSelectedNotification,
  pickUpVariantLabelColors,
  white,
} from "globals/design-system/colors";
import { ClipboardIcon, NoteIcon, TripIcon } from "globals/design-system/icons";
import { CircleButton, RoundChip } from "components/ui";
import StopTime from "components/shared/Stop/StopTime";
import { getGoogleMapsURL, getStopLocation } from "globals/utils/helpers";
import { PickUpVariant, Stop } from "types";
import moment from "moment-timezone";

type StopChangeProps = {
  label: string;
  oldStop?: Stop;
  newStop: Stop;
  onStopDetailsClick: () => void;
  isOldStopView: boolean;
  isLastStop: boolean;
  oldPickUpStop?: Stop;
  oldDropOffStop?: Stop;
};

const pickUpVariantLabels = {
  [PickUpVariant.MeetGreet]: "MEET & GREET",
  [PickUpVariant.Curbside]: "CURBSIDE",
};

const StopChange = (props: StopChangeProps) => {
  const {
    label,
    oldPickUpStop,
    oldDropOffStop,
    newStop,
    onStopDetailsClick,
    isLastStop,
  } = props;

  const isPickup = newStop.stopIndex === 1;
  const isDateTimeSame =
    moment
      .utc(newStop.dateTime)
      .diff(moment.utc(newStop.originalDateTime), "minutes") === 0;

  const hasPickUpStopTimeChanged =
    isPickup &&
    newStop.dateTime &&
    oldPickUpStop?.dateTime &&
    moment.utc(oldPickUpStop.dateTime).format("h:mm a") !==
      moment.utc(newStop.dateTime).format("h:mm a");

  const hasDropOffStopTimeChanged =
    isLastStop &&
    ((newStop.dateTime &&
      oldDropOffStop?.dateTime &&
      moment.utc(oldDropOffStop.dateTime).format("h:mm a") !==
        moment.utc(newStop.dateTime).format("h:mm a")) ||
      (newStop?.dateTime && !oldDropOffStop?.dateTime) ||
      (!newStop?.dateTime && oldDropOffStop?.dateTime));

  const stopLocationText = getStopLocation(newStop);

  const oldPickUpStopLocationText = getStopLocation(oldPickUpStop);
  const oldDropOffStopLocationText = getStopLocation(oldDropOffStop);

  const hasOldPickUpLocationChanged =
    isPickup &&
    oldPickUpStopLocationText &&
    oldPickUpStopLocationText !== stopLocationText;

  const hasOldDropOffUpLocationChanged =
    isLastStop &&
    oldDropOffStopLocationText &&
    oldDropOffStopLocationText !== stopLocationText;

  const hasStopTimeChanged =
    hasPickUpStopTimeChanged || hasDropOffStopTimeChanged;
  const hasLocationChanged =
    hasOldPickUpLocationChanged || hasOldDropOffUpLocationChanged;
  const hasNotification = hasStopTimeChanged || hasLocationChanged;

  return (
    <Box
      p={2}
      display="flex"
      flex="1"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column">
        <Box
          key={label}
          display="flex"
          flexDirection="row"
          alignItems="center"
          mr={2}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 26,
              height: 26,
              marginRight: "7px",
              backgroundColor: moovsBlueSelectedNotification,
              borderRadius: "50%",
            }}
          >
            <ClipboardIcon color={moovsBlue} size="small" />
          </Box>

          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="overline">{label}</Typography>
              {newStop.pickUpVariant && (
                <RoundChip
                  {...pickUpVariantLabelColors[newStop.pickUpVariant]}
                  label={pickUpVariantLabels[newStop.pickUpVariant]}
                />
              )}
            </Box>

            <StopTime
              isPickup={isPickup}
              flightOffset={newStop.flightOffset}
              isDateTimeSame={isDateTimeSame}
              originalDateTime={newStop.originalDateTime}
              dateTime={newStop.dateTime}
            />
          </Box>
        </Box>
        <Box ml="33px" maxWidth="375px" pt={2}>
          <Typography variant="body2">{stopLocationText}</Typography>

          {hasNotification && (isLastStop || isPickup) && (
            <Box>
              <Typography variant="caption" color={grayDark} pt={1}>
                Original:
              </Typography>

              <Typography
                variant="body2"
                style={{
                  textDecorationLine: "line-through",
                  color: grayDark,
                  marginRight: "5px",
                  fontSize: "12px",
                }}
              >
                {hasStopTimeChanged &&
                  isPickup &&
                  moment.utc(oldPickUpStop.dateTime).format("h:mm a")}
                {hasStopTimeChanged && isLastStop && oldDropOffStop?.dateTime
                  ? moment.utc(oldDropOffStop.dateTime).format("h:mm a")
                  : "-"}
                {hasStopTimeChanged && <br />}
                {hasLocationChanged && isPickup && oldPickUpStopLocationText}
                {hasLocationChanged && isLastStop && oldDropOffStopLocationText}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box display="flex" alignItems="center" ml={2}>
        {(!!newStop.note || !!newStop.groupSize) && (
          <Box mr={1.5}>
            <CircleButton onClick={onStopDetailsClick}>
              <NoteIcon color={white} />
            </CircleButton>
          </Box>
        )}
        <Box>
          <CircleButton
            target="_blank"
            href={getGoogleMapsURL(
              newStop.variant === "address"
                ? newStop.location
                : `${newStop.airport?.coordinates?.x},${newStop.airport?.coordinates?.y}`
            )}
          >
            <TripIcon color={white} />
          </CircleButton>
        </Box>
      </Box>
    </Box>
  );
};

export default StopChange;
