import React from "react";
import isNil from "lodash/isNil";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";

import { Box, useMediaQuery, useTheme } from "@mui/material";

import { IconInfoSegment } from "components/ui";
import { TripIcon } from "globals/design-system/icons";
import {
  moovsBlueLightNotification,
  moovsBlueSelectedNotification,
} from "globals/design-system/colors";

type TripTypeBlockProps = {
  tripType: string;
  oldTripType?: string;
};

const TripTypeNotification = (props: TripTypeBlockProps) => {
  const { tripType, oldTripType } = props;

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  if (isNil(oldTripType) || oldTripType === tripType) {
    return (
      <Box mr={1} width={isMobileView ? "50%" : "30%"}>
        <IconInfoSegment label="Trip Type" value={tripType} icon={TripIcon} />
      </Box>
    );
  }
  return (
    <Box
      mr={1}
      border={1}
      borderColor={moovsBlueLightNotification}
      bgcolor={moovsBlueSelectedNotification}
      borderRadius={1}
      width={isMobileView ? "50%" : "30%"}
      p={0.8}
    >
      <IconInfoSegment
        hasNotification
        label="Trip Type"
        value={tripType}
        icon={TripIcon}
        subText={startCase(toLower(oldTripType.replace(/_/g, " ")))}
      />
    </Box>
  );
};

export default TripTypeNotification;
