import { ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const headerMiddlewareLink = (driverId: string) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => {
      return {
        headers: {
          ...headers,
          driverId,
        },
      };
    });

    return forward(operation);
  });

const onErrorLink = (driverId: string, history: any) =>
  onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.map(async (error) => {
        if (error?.extensions?.code === "UNAUTHENTICATED") {
          history.push(`/driver/${driverId}/auth-error`);
        }
      });
    }
  });

export { headerMiddlewareLink, onErrorLink };
