import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import moment from "moment-timezone";

import { Box, Typography } from "@mui/material";

import {
  grayDark,
  white,
  moovsBlue,
  moovsBlueSelected,
  pickUpVariantLabelColors,
} from "globals/design-system/colors";
import { PickUpVariant, Stop } from "types";
import { ClipboardIcon, TripIcon, NoteIcon } from "globals/design-system/icons";
import FlightInfoCard from "components/shared/FlightInfoCard";
import { getGoogleMapsURL } from "globals/utils/helpers";
import { useSnackbar } from "globals/hooks/useSnackbar";
import { UPDATE_TRACKED_FLIGHT_MUTATION } from "globals/graphql";
import FlightIcon from "globals/design-system/icons/info/FlightIcon";
import { CircleButton, RoundChip } from "components/ui";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

type StopItemProps = {
  stop: Stop;
  label: string;
  onStopDetailsClick: () => void;
};

const pickUpVariantLabels = {
  [PickUpVariant.MeetGreet]: "MEET & GREET",
  [PickUpVariant.Curbside]: "CURBSIDE",
};

function StopItem(props: StopItemProps) {
  const {
    stop: {
      location,
      locationAlias,
      dateTime,
      note,
      groupSize,
      airport,
      trackedFlight,
      variant,
      pickUpVariant,
      stopIndex,
      originalDateTime,
      flightOffset,
    },
    label,

    onStopDetailsClick,
  } = props;

  const snackbar = useSnackbar();

  // state
  const [isRefreshingFlight, setIsRefreshingFlight] = useState(false);

  // derived state
  const isPickup = stopIndex === 1;
  const isDateTimeSame =
    moment.utc(dateTime).diff(moment.utc(originalDateTime), "minutes") === 0;

  // mutations
  const [updateTrackedFlight] = useMutation(UPDATE_TRACKED_FLIGHT_MUTATION, {
    onCompleted(data) {
      setIsRefreshingFlight(false);
    },
    onError(error) {
      setIsRefreshingFlight(false);
      const errorMessage = getErrorMessage(error) || "Error updating flight";

      snackbar.error(errorMessage);
      console.error(error);
    },
  });

  // event handlers
  const handleRefreshFlightClick = () => {
    setIsRefreshingFlight(true);

    updateTrackedFlight({
      variables: {
        input: {
          flightNumber:
            trackedFlight.actualFlightNumber || trackedFlight.flightNumber,
          trackedFlightId: trackedFlight.id,
        },
      },
    });
  };

  const stopLocationText =
    variant === "address"
      ? locationAlias || location
      : `${airport?.iataCode} - ${location}`;

  return (
    <Box display="flex" flexDirection="column">
      <Box
        py={2}
        display="flex"
        flex="1"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="column">
          <Box
            key={label}
            display="flex"
            flexDirection="row"
            alignItems="center"
            mr={2}
          >
            <Box display="flex">
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  width: 26,
                  height: 26,
                  marginRight: "7px",
                  backgroundColor: moovsBlueSelected,
                  borderRadius: "50%",
                }}
              >
                <ClipboardIcon color={moovsBlue} size="small" />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="overline">{label}</Typography>
                {pickUpVariant && (
                  <RoundChip
                    {...pickUpVariantLabelColors[pickUpVariant]}
                    label={pickUpVariantLabels[pickUpVariant]}
                  />
                )}
              </Box>

              <Typography
                sx={{
                  lineHeight: "24px",
                  fontSize: 16,
                  fontWeight: "bold",
                  height: 16,
                  whiteSpace: "nowrap",
                }}
              >
                {isPickup && flightOffset && !isDateTimeSame ? (
                  <Box display="flex" flexDirection="row">
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: "bold",
                        textDecorationLine: "line-through",
                        color: grayDark,
                        marginRight: "5px",
                      }}
                    >
                      {moment.utc(originalDateTime).format("h:mm A")}
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="body1"
                        style={{ fontWeight: "bold", marginRight: 4 }}
                      >
                        {moment.utc(dateTime).format("h:mm A")}
                      </Typography>
                      <FlightIcon size="small" />
                    </Box>
                  </Box>
                ) : (
                  <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    {dateTime ? moment.utc(dateTime).format("h:mm a") : "-"}
                  </Typography>
                )}
              </Typography>
            </Box>
          </Box>
          <Box ml="33px" maxWidth="375px" pt={2}>
            <Typography variant="body2">{stopLocationText}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" ml={2}>
          {(!!note || !!groupSize) && (
            <Box mr={1.5}>
              <CircleButton onClick={onStopDetailsClick}>
                <NoteIcon color={white} />
              </CircleButton>
            </Box>
          )}
          <Box>
            <CircleButton
              target="_blank"
              href={getGoogleMapsURL(
                variant === "address"
                  ? location
                  : `${airport.coordinates.x},${airport.coordinates.y}`
              )}
            >
              <TripIcon color={white} />
            </CircleButton>
          </Box>
        </Box>
      </Box>
      {trackedFlight && (
        <FlightInfoCard
          trackedFlight={trackedFlight}
          refreshFlightProps={{
            isRefreshingFlight,
            onClickRefreshFlight: handleRefreshFlightClick,
          }}
        />
      )}
    </Box>
  );
}

export default StopItem;
