import React from "react";

type SyncIconProps = {
  color?: string;
  size?: "small";
};

const SyncIcon = (props: SyncIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 9.42919L18.7952 5L23.2244 5.79518M19.032 5.245C20.5837 6.86157 21.5313 8.9632 21.7155 11.1964C21.8997 13.4296 21.3093 15.6581 20.0434 17.507C18.7776 19.356 16.9137 20.7127 14.7651 21.3488C12.6165 21.985 10.3144 21.8618 8.246 21M6.69301 15.4244L5.26862 19.693L1 18.2686M4.967 18.751C3.41868 17.1336 2.47401 15.0327 2.29162 12.8011C2.10923 10.5696 2.70022 8.34316 3.96537 6.49582C5.23051 4.64848 7.09275 3.2927 9.23938 2.65613C11.386 2.01957 13.6863 2.14099 15.754 3"
          stroke={color || "#B3B3B3"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 9.42919L18.7952 5L23.2244 5.79518M19.032 5.245C20.5837 6.86157 21.5313 8.9632 21.7155 11.1964C21.8997 13.4296 21.3093 15.6581 20.0434 17.507C18.7776 19.356 16.9137 20.7127 14.7651 21.3488C12.6165 21.985 10.3144 21.8618 8.246 21M6.69301 15.4244L5.26862 19.693L1 18.2686M4.967 18.751C3.41868 17.1336 2.47401 15.0327 2.29162 12.8011C2.10923 10.5696 2.70022 8.34316 3.96537 6.49582C5.23051 4.64848 7.09275 3.2927 9.23938 2.65613C11.386 2.01957 13.6863 2.14099 15.754 3"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SyncIcon;
