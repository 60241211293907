import React from "react";
import { Helmet } from "react-helmet";
import { useParams, useHistory } from "react-router-dom";

import { Typography, Box, Button } from "@mui/material";

function AuthErrorPage() {
  const { driverId } = useParams<{ driverId: string }>();
  const history = useHistory();

  const handleBackToMyTripsClick = () => {
    history.push(`/driver/${driverId}/`);
  };
  return (
    <>
      <Helmet>
        <title>Error</title>
      </Helmet>
      <Box
        position="fixed"
        display="flex"
        flexDirection="column"
        flex="1"
        height="100%"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        mx={8}
      >
        <Typography variant="h2">
          This trip is no longer assigned to you
        </Typography>
        <Box mt={3}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleBackToMyTripsClick}
          >
            Back to My Trips
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default AuthErrorPage;
