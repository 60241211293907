import { useCallback, useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";

import { useSegment } from "./useSegment";
import { useDriver } from "./useDriver";
import { fromGlobalId } from "globals/utils/fromGlobalId";

function useIdentifyAnalytics() {
  // hooks
  const analytics = useSegment();
  const { driver } = useDriver();

  const [hasInitialized, setHasInitialized] = useState(false);

  // handlers
  const identify = useCallback(() => {
    if (!driver || !analytics) return;

    // NOTE: This is just to update group analytics in mixpanel,
    // due to a bug where segment does not update the values
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

    const { id, firstName, lastName, email, operator } = driver;
    const operatorId = fromGlobalId(operator.id).id;

    analytics.identify(fromGlobalId(id).id, {
      email,
      operator_id: operatorId,
      name: `${firstName} ${lastName}`,
      source: "driver-link",
    });

    const groupAnalytics = {
      name: operator.name,
      operator_id: operatorId,
      phone: operator.voicePhoneNumber,
      email: operator.generalEmail,
      location: operator.address,
      source: "driver-link",
      plan: operator.plan,
      stripe_id: operator.stripeAccountId,
    };

    analytics.group(operatorId, groupAnalytics);
    mixpanel.get_group("operator_id", operatorId).set(groupAnalytics);

    setHasInitialized(true);
  }, [analytics, driver]);

  // initialize app
  useEffect(() => {
    if (
      driver && // operator has fetched
      analytics && // analytics has loaded
      !hasInitialized
    ) {
      identify();
    }
  }, [analytics, driver, hasInitialized, identify]);

  return { identify };
}

export { useIdentifyAnalytics };
