import React from "react";
import moment from "moment-timezone";

import { Box, Typography } from "@mui/material";

import FlightIcon from "globals/design-system/icons/info/FlightIcon";
import { grayDark } from "globals/design-system/colors";

type StopTimeProps = {
  isPickup: boolean;
  flightOffset?: number;
  isDateTimeSame: boolean;
  originalDateTime: string;
  dateTime: string;
};

const StopTime = (props: StopTimeProps) => {
  const { isPickup, flightOffset, isDateTimeSame, originalDateTime, dateTime } =
    props;
  return (
    <Typography
      sx={{
        lineHeight: "24px",
        fontSize: 16,
        fontWeight: "bold",
        height: 16,
        whiteSpace: "nowrap",
      }}
    >
      {isPickup && flightOffset && !isDateTimeSame ? (
        <Box display="flex" flexDirection="row">
          <Typography
            variant="body1"
            style={{
              fontWeight: "bold",
              textDecorationLine: "line-through",
              color: grayDark,
              marginRight: "5px",
            }}
          >
            {moment.utc(originalDateTime).format("h:mm A")}
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography
              variant="body1"
              style={{ fontWeight: "bold", marginRight: 4 }}
            >
              {moment.utc(dateTime).format("h:mm A")}
            </Typography>
            <FlightIcon size="small" />
          </Box>
        </Box>
      ) : (
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          {dateTime ? moment.utc(dateTime).format("h:mm a") : "-"}
        </Typography>
      )}
    </Typography>
  );
};

export default StopTime;
