/**
 * @file PassengerContactBlock.tsx
 * Passesnger Contact section of driver dispatch view
 *
 * components:
 *  PassengerContactBlock
 */

import React, { useState, useMemo, useEffect } from "react";
import NoSleep from "nosleep.js";
import last from "lodash/last";
import first from "lodash/first";

import { Box, Button, Card, Typography } from "@mui/material";

import { grayLight, moovsBlue, white } from "globals/design-system/colors";

import { MessageIcon, PhoneIcon, SignIcon } from "globals/design-system/icons";
import GreetingSignDrawer from "../../pages/DisptachPage/components/GreetingSignDrawer/GreetingSignDrawer";
import { CircleButton } from "components/ui";

type PassengerContactBlockProps = {
  name: string;
  greetingSignName: string;
  phone: string;
  shouldConcealPassengerInfo: boolean;
  isTemporaryPassenger: boolean;
  companyName?: string;
};

function PassengerContactBlock(props: PassengerContactBlockProps) {
  const {
    name,
    greetingSignName,
    phone,
    shouldConcealPassengerInfo,
    isTemporaryPassenger,
    companyName,
  } = props;

  const [isSignDrawerOpen, setIsSignDrawerOpen] = useState(false);

  const noSleep = useMemo(() => new NoSleep(), []);

  // derived state
  const passengerName = shouldConcealPassengerInfo
    ? getAbbreviatedName(name)
    : name;
  const phoneNumber = shouldConcealPassengerInfo ? "" : phone || "-";
  const shouldDisablePhone = !phone || shouldConcealPassengerInfo;
  const testId = shouldConcealPassengerInfo
    ? "concealed-passenger-info"
    : "full-passenger-info";

  // event handlers
  const handleGreetingSignDrawerOpen = () => {
    setIsSignDrawerOpen(true);
    noSleep.enable();
  };

  const handleGreetingSignDrawerClose = () => {
    setIsSignDrawerOpen(false);
    noSleep.disable();
  };

  useEffect(() => {
    return () => {
      noSleep.disable();
    };
  }, [noSleep]);

  return (
    <>
      <Box mb={2}>
        <Typography variant="h3">Passenger Contact</Typography>
      </Box>
      <Card
        variant="outlined"
        {...(shouldConcealPassengerInfo && {
          style: { background: grayLight },
        })}
      >
        <Box
          p={2}
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            ml="1px"
            data-testid={testId}
            sx={{ maxWidth: "60%", wordWrap: "break-word" }}
          >
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              {passengerName}
            </Typography>
            <Typography
              variant="body2"
              {...(shouldConcealPassengerInfo && {
                style: {
                  width: 100,
                  height: 20,
                  background: "#E2E2E2",
                  marginTop: 8,
                },
              })}
            >
              {phoneNumber}
            </Typography>
          </Box>

          <Box display="flex" flexDirection="row">
            <Box mr={1.5}>
              <CircleButton
                disabled={shouldDisablePhone}
                component="a"
                {...(!shouldConcealPassengerInfo && {
                  href: `sms:${phone}`,
                })}
              >
                <MessageIcon color={white} />
              </CircleButton>
            </Box>
            <CircleButton
              disabled={shouldDisablePhone}
              component="a"
              {...(!shouldConcealPassengerInfo && {
                href: `tel:${phone}`,
              })}
            >
              <PhoneIcon color={white} />
            </CircleButton>
          </Box>
        </Box>
      </Card>
      <Box
        sx={{
          flex: 1,
          mt: 2,
          width: "50%",
          display: "flex",
        }}
      >
        <Button
          color="primary"
          fullWidth
          variant="outlined"
          endIcon={<SignIcon size="small" color={moovsBlue} />}
          onClick={handleGreetingSignDrawerOpen}
        >
          Greeting Sign
        </Button>
      </Box>
      <GreetingSignDrawer
        isSignDrawerOpen={isSignDrawerOpen}
        greetingSignName={greetingSignName}
        isTemporaryPassenger={isTemporaryPassenger}
        onGreetingSignDrawerClose={handleGreetingSignDrawerClose}
        companyName={companyName}
      />
    </>
  );
}

export default PassengerContactBlock;

// take last letters
export const getAbbreviatedName = (name: string) => {
  const nameParts = name.split(" ");
  const lastInitial = nameParts.length > 1 && first(last(nameParts));

  if (lastInitial) nameParts.pop();

  return `${nameParts.join(" ")} ${lastInitial ? lastInitial + "." : ""}`;
};
