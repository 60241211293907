/**
 * @file TripPlannerBlock.tsx
 * Trip Planner section of driver dispatch view
 *
 * components:
 *  TripPlannerBlock
 */
import React, { useState } from "react";

import { Box, Typography, Divider, Card } from "@mui/material";

import { Stop } from "types";
import { StopItem } from "./components";
import StopDetailsDrawer from "components/shared/Stop/StopDetailsDrawer";
import { getStopLabel } from "globals/utils/helpers";

type TripPlannerBlockProps = {
  stops: Stop[];
};

function TripPlannerBlock(props: TripPlannerBlockProps) {
  const { stops } = props;

  // state
  const [stopDetailsIndex, setStopDetailsIndex] = useState(null);

  return (
    <>
      <Box mb={2}>
        <Box mb={2}>
          <Typography variant="h3">Trip Planner</Typography>
        </Box>
        <Card variant="outlined">
          <Box my={2} px={2}>
            {stops.map((stop: Stop, index) => {
              const isLastStop = stop.stopIndex === stops.length;

              return (
                <Box key={stop.id}>
                  <StopItem
                    stop={stop}
                    label={getStopLabel(stops, stop.stopIndex)}
                    onStopDetailsClick={() => setStopDetailsIndex(index)}
                  />
                  {!isLastStop && <Divider />}
                </Box>
              );
            })}
          </Box>
        </Card>
      </Box>

      <StopDetailsDrawer
        stops={stops}
        stopDetailsIndex={stopDetailsIndex}
        setStopDetailsIndex={setStopDetailsIndex}
      />
    </>
  );
}

export default TripPlannerBlock;
