import React from "react";
import some from "lodash/some";
import isNil from "lodash/isNil";
import every from "lodash/every";

import { Box, Typography } from "@mui/material";
import {
  grayDark,
  moovsBlueLightNotification,
  moovsBlueSelectedNotification,
} from "globals/design-system/colors";
import {
  ChildSeatsNoteBlock,
  LuggageBlock,
} from "components/shared/TripNotesBlock/components";
import NotificationIcon from "globals/design-system/icons/info/NotificationIcon";
import { getChildSeats, SeatType } from "globals/utils/helpers";

type TripNotesNotificationProps = {
  note: string;
  oldNote?: string;
  luggage: {
    carryOnLuggage: number;
    checkedLuggage: number;
    oversizeLuggage: number;
  };
  childSeats?: SeatType;
  oldChildSeats?: SeatType;
};

function TripNotesNotification(props: TripNotesNotificationProps) {
  const { note, oldNote, luggage, oldChildSeats, childSeats } = props;

  // derived state
  const hasLuggage = some(luggage, (luggageType) => !!luggageType);

  const oldSeats = getChildSeats(oldChildSeats, childSeats, true);
  const newSeats = getChildSeats(oldChildSeats, childSeats, false);
  const hasOldChildSeats = oldChildSeats && some(oldSeats, (qty) => qty > 0);
  const hasNewChildSeats = newSeats && some(newSeats, (qty) => qty > 0);
  const hasChildSeatsNotChanged =
    oldChildSeats && every(oldChildSeats, (qty) => isNil(qty));

  const hasNotesChanged = !isNil(oldNote);
  const hasNotification =
    hasNotesChanged ||
    !hasChildSeatsNotChanged ||
    (hasOldChildSeats && hasNewChildSeats) ||
    (!hasNewChildSeats && hasOldChildSeats && !hasChildSeatsNotChanged);

  const shouldShowPreviousNilChildSeats =
    !hasNotesChanged &&
    hasNewChildSeats &&
    !hasChildSeatsNotChanged &&
    !(hasNewChildSeats && hasOldChildSeats);

  return (
    <Box
      mb={1}
      {...(hasNotification
        ? {
            border: 1,
            borderColor: moovsBlueLightNotification,
            bgcolor: moovsBlueSelectedNotification,
            borderRadius: 1,
            width: "100%",
            p: 1,
          }
        : {})}
    >
      <Box display="flex" alignItems="center" gap={0.8}>
        <Typography variant="h3">Trip Notes</Typography>
        {hasNotification && <NotificationIcon />}
      </Box>
      <Box my={1.5}>
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "break-spaces",
            color: !note && grayDark,
            fontSize: "14px",
          }}
        >
          {note || "No Trip Notes"}
        </Typography>

        {hasNotesChanged && (
          <Box display="flex" gap={0.8} mt={0.4}>
            <Typography
              variant="body2"
              style={{
                marginTop: 2,
                fontWeight: 400,
                lineHeight: "16px",
                fontSize: "12px",
                textDecorationLine: "none",
                color: grayDark,
              }}
            >
              Original:
            </Typography>

            <Typography
              variant="body2"
              style={{
                marginTop: 2,
                fontWeight: 400,
                lineHeight: "16px",
                fontSize: "12px",
                textDecorationLine: "line-through",
                color: grayDark,
              }}
            >
              {oldNote || "No Trip Notes"}
            </Typography>
          </Box>
        )}
      </Box>

      {hasLuggage && <LuggageBlock luggage={luggage} />}
      <ChildSeatsNoteBlock {...newSeats} />
      <ChildSeatsNoteBlock {...oldSeats} isOld />

      {shouldShowPreviousNilChildSeats && (
        <Box display="flex" gap={0.8} mt={0.4}>
          <Typography
            variant="body2"
            style={{
              marginTop: 2,
              fontWeight: 400,
              lineHeight: "16px",
              fontSize: "12px",
              textDecorationLine: "none",
              color: grayDark,
            }}
          >
            Original:
          </Typography>

          <Typography
            variant="body2"
            style={{
              marginTop: 2,
              fontWeight: 400,
              lineHeight: "16px",
              fontSize: "12px",
              textDecorationLine: "line-through",
              color: grayDark,
            }}
          >
            {"No Child Seats"}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default TripNotesNotification;
