import React from "react";
import { Card, CardActionArea, Box, Typography, Divider } from "@mui/material";
import moment from "moment-timezone";

import { OperatorRoute } from "types";
import {
  grayDark,
  black,
  rideStatusLabelColors,
  moovsBlueHover,
  moovsBlueLightNotification,
  moovsBlueSelectedNotification,
} from "globals/design-system/colors";
import { RoundChip } from "components/ui";
import { DispatchStatusEnum } from "globals/utils/helpers";
import NotificationIcon from "globals/design-system/icons/info/NotificationIcon";

type RouteListItemProps = {
  route: OperatorRoute;
  hasNotification: boolean;
  onRouteClick: (routeId) => void;
};

function RouteListItem(props: RouteListItemProps) {
  const { route, onRouteClick, hasNotification } = props;

  const confirmationNumber = route.trip.tripNumber
    ? `${route.request.orderNumber}-${route.trip.tripNumber}`
    : route.request.orderNumber;

  const routeVehicle = route.vehicle?.name
    ? route.vehicle.name
    : "No Vehicle Name";

  const routeDateTime = route.trip.stops[0].dateTime
    ? moment.utc(route.trip.stops[0].dateTime).format("LLLL")
    : "Date/time missing";

  const isTemporaryPassenger = !!route.trip.temporaryPassenger?.name;

  const routePassengerFullName = isTemporaryPassenger
    ? route.trip.temporaryPassenger.name
    : route.trip.contact
    ? `${route.trip.contact.firstName} ${route.trip.contact.lastName}`
    : "No Passenger Name";

  // custom Styles
  const columnHeaderCustomStyle = {
    color: grayDark,
  };
  const infoCustomStyle = {
    color: black,
  };

  return (
    <Card variant="outlined">
      <CardActionArea
        disableRipple
        onClick={() => onRouteClick(route.id)}
        sx={{
          ...(hasNotification
            ? {
                border: 1,
                borderColor: moovsBlueLightNotification,
                bgcolor: moovsBlueSelectedNotification,
                borderRadius: 1,
                width: "100%",
              }
            : {}),
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          flex=".4"
          py={2}
          px={2}
          alignItems="center"
        >
          <Box display="flex" flex="1" flexDirection="column">
            <Typography variant="overline" sx={{ columnHeaderCustomStyle }}>
              conf. no.
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: black, fontWeight: "bold" }}
            >
              {confirmationNumber}
            </Typography>
          </Box>
          <Box
            ml={1}
            display="flex"
            flex="2"
            flexDirection="column"
            alignSelf="baseline"
          >
            <Typography variant="overline" sx={{ columnHeaderCustomStyle }}>
              Vehicle
            </Typography>
            <Typography variant="caption" sx={{ infoCustomStyle }}>
              {routeVehicle}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <RoundChip
              inputProps={{
                style: {
                  cursor: "pointer",
                  backgroundColor: hasNotification ? moovsBlueHover : undefined,
                },
              }}
              label={DispatchStatusEnum[route.statusSlug]}
              {...rideStatusLabelColors[route.statusSlug]}
            />
            {hasNotification && <NotificationIcon />}
          </Box>
        </Box>
        <Box mx={2} mb={2}>
          <Divider />
        </Box>
        <Box display="flex" flexDirection="column" flex="1" mb={1} mx={2}>
          <Box display="flex" flex="1">
            <Typography variant="overline" sx={{ columnHeaderCustomStyle }}>
              Passenger
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" flex="1">
            <Box mr={1}>
              <Typography variant="caption" sx={{ infoCustomStyle }}>
                {routePassengerFullName}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" flex="1" mb={2} mx={2}>
          <Box display="flex" flex="1">
            <Typography variant="overline" sx={{ columnHeaderCustomStyle }}>
              Trip Date & Time
            </Typography>
          </Box>
          <Box display="flex" flex="1">
            <Typography variant="caption" sx={{ infoCustomStyle }}>
              {routeDateTime}
            </Typography>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default RouteListItem;
