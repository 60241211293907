import React from "react";
import isNil from "lodash/isNil";

import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  grayDark,
  moovsBlueLightNotification,
  moovsBlueSelectedNotification,
} from "globals/design-system/colors";
import FlightIcon from "globals/design-system/icons/info/FlightIcon";
import NotificationIcon from "globals/design-system/icons/info/NotificationIcon";
import TripTimeBlock from "components/shared/TripTimeBlock";

type TripTimeNotificationProps = {
  pickUpPreviousTime: string;
  pickUpTime: string;
  flightOffset: number;
};

const TripTimeNotification = (props: TripTimeNotificationProps) => {
  const { pickUpTime, flightOffset, pickUpPreviousTime } = props;

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  if (isNil(pickUpPreviousTime) || pickUpPreviousTime === pickUpTime) {
    return (
      <TripTimeBlock
        tripTime={pickUpTime}
        tripOriginalTime={pickUpPreviousTime}
        flightOffset={flightOffset}
      />
    );
  }

  return (
    <Box
      mr={2}
      border={1}
      borderColor={moovsBlueLightNotification}
      bgcolor={moovsBlueSelectedNotification}
      borderRadius={1}
      width={isMobileView ? "30%" : "20%"}
      p={0.8}
    >
      <Box display="flex" alignContent="center" alignItems="center" gap={0.8}>
        <Typography variant="overline" style={{ color: grayDark }}>
          Time
        </Typography>
        <NotificationIcon />
      </Box>
      <Box>
        <Box display="flex" alignItems="center">
          <Typography
            variant="body1"
            style={{ fontWeight: "bold", marginRight: 5 }}
          >
            {pickUpTime}
          </Typography>
          {!!flightOffset && <FlightIcon size="small" />}
        </Box>

        <Typography
          variant="body2"
          style={{
            fontWeight: 400,
            lineHeight: "16px",
            fontSize: "12px",
            textDecorationLine: "line-through",
            color: grayDark,
          }}
        >
          {pickUpPreviousTime}
        </Typography>
      </Box>
    </Box>
  );
};

export default TripTimeNotification;
