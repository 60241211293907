/**
 * @file graphql/driverPayout.ts
 * queries/mutations for DriverPayouts and DriverPaybles.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_INDIVIDUAL_DRIVER_PAYOUTS = gql`
  query IndividualDriverPayouts($driverId: ID!) {
    individualDriverPayouts(driverId: $driverId) {
      operatorRoutes {
        id
        gratuityAmt
        flatRateAmt
        hourlyAmt
        totalAmt
        completeOrderNumber
        trip {
          id
          stops {
            id
            dateTime
          }
        }
      }
      routesCount
      flatRateAmt
      gratuityAmt
      hourlyAmt
      totalAmt
      payoutCreatedAt
    }
  }
`;
