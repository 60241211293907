import React from "react";

type TruckIconProps = {
  color?: string;
  size?: "small";
};

const TruckIcon = (props: TruckIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.8 7.92435V3.75759C9.8 3.55667 9.71783 3.36397 9.57155 3.22189C9.42527 3.07982 9.22687 3 9.02 3H2.78001C2.57314 3 2.37474 3.07982 2.22846 3.22189C2.08218 3.36397 2.00001 3.55667 2.00001 3.75759V9.43954C1.99959 9.54482 2.02178 9.64904 2.06515 9.74553C2.10853 9.84202 2.17213 9.92867 2.25191 9.99996C2.33168 10.0712 2.42588 10.1256 2.52849 10.1595C2.63111 10.1935 3.39201 11.0092 3.5 11M9.79999 10.2V4.8H11.48C12.1483 4.8 12.7893 5.03542 13.2619 5.45446C13.7345 5.87351 14 6.44186 14 7.03448V9.45517C14 9.65271 13.9115 9.84216 13.754 9.98185C13.5964 10.1215 13.3828 10.2 13.16 10.2H10.5M9.5 10.4H2.99999"
          stroke={color || "#B3B3B3"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 13C11.5523 13 12 12.5523 12 12C12 11.4477 11.5523 11 11 11C10.4477 11 10 11.4477 10 12C10 12.5523 10.4477 13 11 13Z"
          fill={color || "#B3B3B3"}
          stroke={color || "#B3B3B3"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 13C4.55228 13 5 12.5523 5 12C5 11.4477 4.55228 11 4 11C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13Z"
          fill={color || "#B3B3B3"}
          stroke={color || "#B3B3B3"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 13.2073V6.26265C15 5.92778 14.863 5.60662 14.6192 5.36982C14.3754 5.13303 14.0448 5 13.7 5H3.30001C2.95523 5 2.62457 5.13303 2.38077 5.36982C2.13697 5.60662 2.00001 5.92778 2.00001 6.26265V15.7326C1.99932 15.908 2.0363 16.0817 2.10859 16.2425C2.18088 16.4034 2.28688 16.5478 2.41985 16.6666C2.55281 16.7854 2.70981 16.876 2.88082 16.9326C3.05184 16.9891 3.23313 17.0105 3.41311 16.9952"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 17V8H17.8C18.9139 8 19.9822 8.39236 20.7698 9.09077C21.5575 9.78919 22 10.7364 22 11.7241V15.7586C22 16.0879 21.8525 16.4036 21.59 16.6364C21.3274 16.8692 20.9713 17 20.6 17H19"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 13H19.3333C18.9797 13 18.6406 12.8468 18.3905 12.574C18.1405 12.3012 18 11.9312 18 11.5455V9"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 20C18.1046 20 19 19.1046 19 18C19 16.8954 18.1046 16 17 16C15.8954 16 15 16.8954 15 18C15 19.1046 15.8954 20 17 20Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33325 20C6.43782 20 7.33325 19.1046 7.33325 18C7.33325 16.8954 6.43782 16 5.33325 16C4.22868 16 3.33325 16.8954 3.33325 18C3.33325 19.1046 4.22868 20 5.33325 20Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 17.3333H7.21997"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TruckIcon;
