/**
 * @file Router.tsx
 * Main router for moovs-driver.
 *
 * reactComponents:
 * Router
 */

import React from "react";
import { Switch, Route } from "react-router-dom";

import MoovsApolloProvider from "../apollo-provider/MoovsApolloProvider";
import AuthErrorPage from "../pages/AuthErrorPage";
import SandboxPage from "../playground/SandboxPage";
import FourZeroFourPage from "../pages/404Page";
import Layout from "../Layout";
import { Helmet } from "react-helmet";
import DriverPage from "pages/DriverPage";
import RouteFeedbackPage from "pages/RouteFeedbackPage";
import DispatchPage from "pages/DisptachPage";
import { useIdentifyAnalytics } from "globals/hooks/useIdentifyAnalytics";
import { useTrackLDClient } from "globals/hooks/useTrackLDClient";
import TripChangeNotificationPage from "pages/TripChangeNotificationPage";
import { DriverPlatformBanner } from "components/layout";

const isDevelopment = process.env.NODE_ENV === "development";

function Router() {
  return (
    <>
      <Helmet>
        <title>Driver | Moovs</title>
      </Helmet>
      <Layout>
        <Switch>
          <Route path="/driver/:driverId" component={DriverRouter} />

          {isDevelopment && <Route path="/sandbox" component={SandboxPage} />}
          <Route path="*" component={FourZeroFourPage} />
        </Switch>
      </Layout>
    </>
  );
}

// uses separate router so apollo provider has access to driverId param
function DriverRouter() {
  return (
    <MoovsApolloProvider>
      <DriverPlatformBanner />
      <DriverSwitch />
    </MoovsApolloProvider>
  );
}

// separate switch so useIdentifyAnalytics can have access to useDriver
function DriverSwitch() {
  // hooks
  useIdentifyAnalytics();
  useTrackLDClient();

  return (
    <Switch>
      <Route path="/driver/:driverId" exact component={DriverPage} />
      <Route
        path="/driver/:driverId/auth-error"
        exact
        component={AuthErrorPage}
      />
      <Route
        path="/driver/:driverId/dispatch/:operatorRouteId/feedback"
        exact
        component={RouteFeedbackPage}
      />
      <Route
        path="/driver/:driverId/dispatch/:operatorRouteId"
        exact
        component={DispatchPage}
      />
      <Route
        path="/driver/:driverId/dispatch/:operatorRouteId/notifications"
        exact
        component={TripChangeNotificationPage}
      />
    </Switch>
  );
}

export default Router;
