import React from "react";

import { Box } from "@mui/material";

import { IconInfoSegment } from "components/ui";
import { UserIcon } from "globals/design-system/icons";

type PassengerCountBlockProps = {
  totalGroupSize: number;
};

const PassengerCountBlock = (props: PassengerCountBlockProps) => {
  const { totalGroupSize } = props;
  return (
    <Box mr={1} mb={1}>
      <IconInfoSegment
        label="Passenger count"
        value={totalGroupSize}
        icon={UserIcon}
      />
    </Box>
  );
};

export default PassengerCountBlock;
