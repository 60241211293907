import React from "react";

export const LocationIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 9.86622C12.1471 9.9513 12.3179 10 12.5 10C13.0523 10 13.5 9.55228 13.5 9C13.5 8.53236 13.179 8.1397 12.7453 8.03032M12 9.86622C11.7011 9.69331 11.5 9.37014 11.5 9C11.5 8.62986 11.7011 8.30669 12 8.13378C12.2989 8.30669 12.5 8.62986 12.5 9C12.5 9.37014 12.2989 9.69331 12 9.86622ZM20.5 8.718C20.5 12.4341 14.4176 20.7546 12.8603 22.8231C12.8191 22.8779 12.765 22.9225 12.7025 22.9532C12.64 22.984 12.5708 23 12.5006 23C12.4304 23 12.3612 22.984 12.2987 22.9532C12.2362 22.9225 12.1821 22.8779 12.1409 22.8231C10.5836 20.7546 4.5 12.4341 4.5 8.718C4.5 6.67106 5.34285 4.70795 6.84315 3.26055C8.34344 1.81314 10.3783 1 12.5 1C14.6217 1 16.6566 1.81314 18.1569 3.26055C19.6571 4.70795 20.5 6.67106 20.5 8.718ZM14.5 9C14.5 10.1046 13.6046 11 12.5 11C11.3954 11 10.5 10.1046 10.5 9C10.5 7.89543 11.3954 7 12.5 7C13.6046 7 14.5 7.89543 14.5 9Z"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
