import React from "react";
import isNil from "lodash/isNil";

import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import {
  grayDark,
  moovsBlueLightNotification,
  moovsBlueSelectedNotification,
} from "globals/design-system/colors";
import NotificationIcon from "globals/design-system/icons/info/NotificationIcon";
import TripDateBlock from "components/shared/TripDateBlock";

type TripDateBlockProps = {
  tripDate: string;
  previousTripDate: string;
};
const TripDateNotification = (props: TripDateBlockProps) => {
  const { tripDate, previousTripDate } = props;

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  if (isNil(previousTripDate) || previousTripDate === tripDate) {
    return <TripDateBlock tripDate={tripDate} />;
  }

  return (
    <Box
      mr={2}
      border={1}
      borderColor={moovsBlueLightNotification}
      bgcolor={moovsBlueSelectedNotification}
      borderRadius={1}
      width={isMobileView ? "30%" : "20%"}
      p={0.8}
    >
      <Box display="flex" alignContent="center" alignItems="center" gap={0.8}>
        <Typography variant="overline" style={{ color: grayDark }}>
          Trip Date
        </Typography>
        <NotificationIcon />
      </Box>
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        {tripDate}
      </Typography>

      <Typography
        variant="body2"
        style={{
          fontWeight: 400,
          lineHeight: "16px",
          fontSize: "12px",
          textDecorationLine: "line-through",
          color: grayDark,
        }}
      >
        {previousTripDate}
      </Typography>
    </Box>
  );
};

export default TripDateNotification;
