/**
 * @file useSegment/Provider.tsx
 * ContextProvider for Segment
 */
import React, { useEffect, useState } from "react";
import { Analytics, AnalyticsBrowser } from "@segment/analytics-next";

import SegmentContext from "./Context";

function SegmentProvider({ children }) {
  // state
  const [segment, setSegment] = useState<Analytics>(null);

  // effects
  useEffect(() => {
    const loadSegment = async () => {
      try {
        const [response] = await AnalyticsBrowser.load({
          writeKey: process.env.REACT_APP_SEGMENT_KEY,
        });
        setSegment(response);
      } catch (error) {
        console.log(error);
      }
    };
    loadSegment();
  }, []);

  return (
    <SegmentContext.Provider value={segment}>
      {children}
    </SegmentContext.Provider>
  );
}

export default SegmentProvider;
