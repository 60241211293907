import React from "react";
import moment from "moment-timezone";

import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import { PrioritizedFlightInfo } from "./components";
import { FlightStatus } from "types";

type FlightDetailsProps = {
  transitStop: any;
  transitStopType: "origin" | "destination";
  flightStatus: FlightStatus;
};

function FlightDetails(props: FlightDetailsProps) {
  const { transitStop, transitStopType, flightStatus } = props;

  const {
    scheduledGate,
    scheduledTerminal,
    actualGate,
    actualTerminal,
    scheduledDateTime,
    actualDateTime,
    estimatedDateTime,
    airport: { timezone },
  } = transitStop;

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  const isDestination = transitStopType === "destination";
  const isMobileDestination = isMobileView && isDestination;
  const isCancelledFlight = flightStatus === "CANCELLED";

  const flightTimePriorityQueue = [];
  if (actualDateTime) {
    flightTimePriorityQueue.push({
      timeLabel: "Actual",
      time: actualDateTime,
    });
  } else if (estimatedDateTime) {
    flightTimePriorityQueue.push({
      timeLabel: "Estimated",
      time: estimatedDateTime,
    });
  }
  if (scheduledDateTime) {
    flightTimePriorityQueue.push({
      timeLabel: "Scheduled",
      time: scheduledDateTime,
    });
  }

  const flightDateOrTime = (dateTime, mode: "date" | "time") =>
    moment.tz(dateTime, timezone).format(mode === "date" ? "M/D/YY" : "h:mmA");

  return (
    <Box
      display="flex"
      flexDirection={isMobileView ? "column" : "row"}
      flexWrap="wrap"
      flex={1}
      justifyContent={transitStopType === "origin" ? "flex-start" : "flex-end"}
    >
      <Box
        display="flex"
        mb={1}
        flex={2}
        {...(isMobileDestination && { justifyContent: "flex-end" })}
        {...(isDestination ? { ml: 2 } : { mr: 2 })}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems={isMobileDestination ? "flex-end" : "flex-start"}
        >
          <Typography
            variant="overline"
            {...(isMobileDestination && { align: "right" })}
          >
            {flightTimePriorityQueue[0].timeLabel}
          </Typography>
          <PrioritizedFlightInfo
            transitStopType={transitStopType}
            primaryText={flightDateOrTime(
              flightTimePriorityQueue[0].time,
              "date"
            )}
            secondaryText={
              flightTimePriorityQueue?.[1]
                ? flightDateOrTime(flightTimePriorityQueue[1].time, "date")
                : ""
            }
            primaryProps={{ variant: "body2", style: { fontWeight: 500 } }}
            isTime
            strikethroughAll={isCancelledFlight}
          />
          <PrioritizedFlightInfo
            transitStopType={transitStopType}
            primaryText={flightDateOrTime(
              flightTimePriorityQueue[0].time,
              "time"
            )}
            secondaryText={
              flightTimePriorityQueue?.[1]
                ? flightDateOrTime(flightTimePriorityQueue[1].time, "time")
                : ""
            }
            primaryProps={{ variant: "h2" }}
            isTime
            strikethroughAll={isCancelledFlight}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        mb={1}
        {...(isDestination ? { justifyContent: "flex-end", ml: 2 } : { mr: 2 })}
        flex={1}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems={isMobileDestination ? "flex-end" : "flex-start"}
        >
          <Typography
            variant="overline"
            {...(isMobileDestination && { align: "right" })}
          >
            TERMINAL
          </Typography>
          <PrioritizedFlightInfo
            transitStopType={transitStopType}
            primaryText={actualTerminal}
            secondaryText={scheduledTerminal}
            primaryProps={{ variant: "h3" }}
            strikethroughAll={isCancelledFlight}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flex={1}
        {...(isDestination ? { justifyContent: "flex-end", ml: 2 } : { mr: 2 })}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems={isMobileDestination ? "flex-end" : "flex-start"}
        >
          <Typography
            variant="overline"
            {...(isMobileDestination && { align: "right" })}
          >
            GATE
          </Typography>
          <PrioritizedFlightInfo
            transitStopType={transitStopType}
            primaryText={actualGate}
            secondaryText={scheduledGate}
            primaryProps={{ variant: "h3" }}
            strikethroughAll={isCancelledFlight}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default FlightDetails;
