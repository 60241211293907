import React from "react";
import { useHistory } from "react-router-dom";

import { Box, Button, Divider, IconButton, Typography } from "@mui/material";

import tripCancelledIcon from "globals/design-system/illustrations/tripCancelledIcon.svg";
import { CrossIcon } from "globals/design-system/icons";
import { primaryMainColor } from "globals/design-system/theme";

type TripCancelledViewProps = { driverId: string };

function TripCancelledView(props: TripCancelledViewProps) {
  const { driverId } = props;

  // hooks
  const history = useHistory();

  // event handlers
  const redirectToDriverPage = () => {
    history.push(`/driver/${driverId}`);
  };

  return (
    <Box display="flex" flexDirection="column" flex="1">
      <Box m={0.5}>
        <IconButton onClick={redirectToDriverPage} size="large">
          <CrossIcon color={primaryMainColor} />
        </IconButton>
      </Box>
      <Divider />
      <Box alignItems="center" alignSelf="center" textAlign="center" mt={25}>
        <Box mb={3}>
          <img src={tripCancelledIcon} alt="Trip Cancelled" />
        </Box>
        <Box mb={1}>
          <Typography variant="h2">This trip is cancelled</Typography>
        </Box>
        <Typography>Unfortunately this trip has been cancelled.</Typography>
        <Box mt={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={redirectToDriverPage}
          >
            Return to home
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default TripCancelledView;
