import React from "react";

import { Box } from "@mui/material";
import { IconInfoSegment } from "components/ui";
import { ClockIcon, EstimationIcon } from "globals/design-system/icons";
import { convertMinutesToHoursMinutes } from "globals/utils/helpers";

type EstimatedDurationBlockProps = {
  shouldUseTripTotalDuration: boolean;
  tripTotalDuration: number;
  tripEstimatedDuration: number;
};

const EstimatedDurationBlock = (props: EstimatedDurationBlockProps) => {
  const {
    tripEstimatedDuration,
    tripTotalDuration,
    shouldUseTripTotalDuration,
  } = props;
  return (
    <Box mr={1} mb={1}>
      <IconInfoSegment
        label={
          shouldUseTripTotalDuration ? "total duration" : "estimated duration"
        }
        value={
          shouldUseTripTotalDuration ? (
            convertMinutesToHoursMinutes(tripTotalDuration)
          ) : (
            <Box display="flex" alignItems="center">
              <Box mr={0.5}>
                {convertMinutesToHoursMinutes(tripEstimatedDuration)}
              </Box>
              <EstimationIcon />
            </Box>
          )
        }
        icon={ClockIcon}
      />
    </Box>
  );
};

export default EstimatedDurationBlock;
