import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";

import { Box, Typography, Divider, Card, Button } from "@mui/material";

import { Stop } from "types";
import StopItemNotification from "./StopItemNotificaton";
import StopDetailsDrawer from "components/shared/Stop/StopDetailsDrawer";
import { getStopLabel } from "globals/utils/helpers";
import { LocationIcon } from "globals/design-system/icons/info/LocationIcon";
import { OutdatedIcon } from "globals/design-system/icons/info/OutdatedIcon";
import {
  lightTintRed,
  moovsBlue,
  moovsBlueLightNotification,
  moovsBlueSelectedNotification,
} from "globals/design-system/colors";
import NotificationIcon from "globals/design-system/icons/info/NotificationIcon";

type TripPlannerNotificationProps = {
  stops: Stop[];
  oldStops: Stop[];
};

const CURRENT_STOP_TEXT = "See current stops";
const PREVIOUS_STOP_TEXT = "See previous stops";

function TripPlannerNotification(props: TripPlannerNotificationProps) {
  const { stops, oldStops } = props;

  // state
  const [stopDetailsIndex, setStopDetailsIndex] = useState(null);
  const [shouldShowOldStops, setShouldShowOldStops] = useState(false);

  const handleShowOldStops = () => {
    setShouldShowOldStops((shouldShowOldStops: boolean) => !shouldShowOldStops);
  };

  // derived state
  const hasNotification = !isEmpty(oldStops);
  const isOldStopsView = hasNotification && shouldShowOldStops;

  const stopsOnView = [...(shouldShowOldStops ? oldStops : stops)];

  const oldPickUpStop = oldStops?.find((stop) => stop.stopIndex === 1);
  const oldDropOffStop = oldStops?.find(
    (stop) => stop.stopIndex === oldStops.length
  );

  return (
    <>
      <Box mb={2}>
        <Box
          mb={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h3">Trip Planner</Typography>
          {!isEmpty(oldStops) && (
            <Button onClick={handleShowOldStops}>
              <Typography color={moovsBlue} mr={1}>
                {shouldShowOldStops ? CURRENT_STOP_TEXT : PREVIOUS_STOP_TEXT}
              </Typography>
              {shouldShowOldStops ? <LocationIcon /> : <OutdatedIcon />}
            </Button>
          )}
        </Box>
        <Card variant="outlined">
          <Box
            px={2}
            sx={
              hasNotification
                ? {
                    position: "relative",
                    border: 1,
                    borderColor: moovsBlueLightNotification,
                    bgcolor: isOldStopsView
                      ? lightTintRed
                      : moovsBlueSelectedNotification,
                    borderRadius: 1,
                    m: 1,
                  }
                : null
            }
          >
            {hasNotification && !isOldStopsView && (
              <Box position="absolute" top={10} right={10}>
                <NotificationIcon />
              </Box>
            )}
            {stopsOnView.map((stop: Stop, index) => {
              const isLastStop = stop.stopIndex === stopsOnView.length;

              return (
                <Box key={stop.id}>
                  <StopItemNotification
                    stop={stop}
                    oldDropOffStop={oldDropOffStop}
                    oldPickUpStop={oldPickUpStop}
                    label={getStopLabel(stopsOnView, stop.stopIndex)}
                    onStopDetailsClick={() => setStopDetailsIndex(index)}
                    isOldStopView={shouldShowOldStops}
                    isLastStop={isLastStop}
                  />
                  {!isLastStop && <Divider />}
                </Box>
              );
            })}
          </Box>
        </Card>
      </Box>
      <StopDetailsDrawer
        stopDetailsIndex={stopDetailsIndex}
        setStopDetailsIndex={setStopDetailsIndex}
        stops={stops}
      />
    </>
  );
}

export default TripPlannerNotification;
