import React from "react";
import { Box, Typography } from "@mui/material";
import { grayDark } from "globals/design-system/colors";

type DriverNoteBlockProps = {
  note: string;
};

function DriverNoteBlock(props: DriverNoteBlockProps) {
  const { note } = props;

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h3">Driver Note</Typography>
        <Box my={2}>
          {note ? (
            <Typography variant="body2" sx={{ whiteSpace: "break-spaces" }}>
              {note}
            </Typography>
          ) : (
            <Typography variant="body2" style={{ color: grayDark }}>
              No driver's note
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default DriverNoteBlock;
