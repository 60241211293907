import React from "react";
import { Box, Typography } from "@mui/material";

import { currency } from "globals/utils/helpers";
import { grayDark } from "globals/design-system/colors";

type AmountDueBlockProps = {
  amountDue: number;
};

const AmountDueBlock = (props: AmountDueBlockProps) => {
  const { amountDue } = props;
  const shouldShowAmountDue = typeof amountDue === "number";
  return (
    shouldShowAmountDue && (
      <Box>
        <Typography variant="overline" style={{ color: grayDark }}>
          Amount Due
        </Typography>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          {currency(amountDue)}
        </Typography>
      </Box>
    )
  );
};

export default AmountDueBlock;
