import React from "react";

type PayoutsIconProps = {
  color?: string;
};

const PayoutsIcon = (props: PayoutsIconProps) => {
  const { color } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6667 8.66667V3.33333C20.6667 2.97971 20.5262 2.64057 20.2761 2.39052C20.0261 2.14048 19.687 2 19.3333 2H3.33333C2.97971 2 2.64057 2.14048 2.39052 2.39052C2.14048 2.64057 2 2.97971 2 3.33333V18C2 18.3536 2.14048 18.6928 2.39052 18.9428C2.64057 19.1929 2.97971 19.3333 3.33333 19.3333H8.66667M2 6H20.6667M18 14H16.1929C15.9155 13.9997 15.6468 14.096 15.4327 14.2724C15.2187 14.4488 15.0729 14.6943 15.0203 14.9666C14.9677 15.2389 15.0116 15.521 15.1445 15.7645C15.2774 16.0079 15.4909 16.1974 15.7484 16.3004L17.5831 17.0338C17.84 17.1372 18.053 17.3267 18.1855 17.5699C18.3179 17.8132 18.3616 18.0949 18.309 18.3668C18.2565 18.6387 18.111 18.8839 17.8974 19.0602C17.6839 19.2366 17.4156 19.3331 17.1387 19.3333H15.3333M16.6667 14V13.3333M16.6667 20V19.3333M22 16.6667C22 19.6122 19.6122 22 16.6667 22C13.7211 22 11.3333 19.6122 11.3333 16.6667C11.3333 13.7211 13.7211 11.3333 16.6667 11.3333C19.6122 11.3333 22 13.7211 22 16.6667Z"
        stroke={color || "#B3B3B3"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PayoutsIcon;
