/**
 * @file DispatchPage.tsx
 * Page for drivers to view current ride and update status
 * Accessed via url link where operatorRouteId is in param.
 *
 * components:
 *  DispatchPage
 */

import React, { useMemo, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMutation } from "@apollo/client";
import startCase from "lodash/startCase";
import lowerCase from "lodash/lowerCase";

import {
  Typography,
  Box,
  Divider,
  IconButton,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import { RouteStatusSelect } from "components/ui";
import { DRIVER_ACCEPT_OR_DECLINE_ROUTE_MUTATION } from "globals/graphql";
import { grayDark, grayMedium, white } from "globals/design-system/colors";
import { CrossIcon, CheckIcon } from "globals/design-system/icons";
import { primaryMainColor } from "globals/design-system/theme";

import {
  ExternalOperator,
  FarmAffiliateVariantEnum,
  FarmRelationshipEnum,
  TripCategory,
} from "types";
import { shouldConcealPassengerInfo } from "globals/utils/shouldConcealPassengerInfo";

import {
  VehicleBlock,
  TripPlannerBlock,
  TripAlertsBlock,
  DriverNoteBlock,
  FarmBlock,
  TripCancelledView,
  PassengerContactBlock,
  HeaderBlock,
} from "./components";
import TripNotesBlock from "components/shared/TripNotesBlock";
import ErrorPage from "components/shared/ErrorPage";
import Loading from "components/shared/Loading";
import { getPassengerInfoFromTrip } from "globals/utils/helpers";
import useOperatorRoute from "globals/hooks/useOperatorRoute";
import useNotification from "globals/hooks/useNotification";

function DispatchPage() {
  // hooks
  const { operatorRouteId, driverId } = useParams<{
    operatorRouteId: string;
    driverId: string;
  }>();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  // queries
  const { notification, loading: isNotificationLoading } =
    useNotification(operatorRouteId);
  const {
    operatorRouteData,
    operatorRouteError,
    operatorRouteLoading,
    operatorRouteRefetch,
  } = useOperatorRoute(operatorRouteId, { pollingEnabled: true });

  const operatorRoute = operatorRouteData?.driverRoute;
  const { name: operatorName, voicePhoneNumber: operatorPhone } =
    operatorRouteData?.driverRoute?.operator || {};
  const trip = operatorRoute?.trip;
  const request = operatorRoute?.request;
  const {
    farmRelationship,
    farmAffiliate,
    farmAffiliateVariant,
    carryOnLuggage,
    checkedLuggage,
    oversizeLuggage,
  } = trip?.routes[0] || {};

  // mutations
  const [acceptOrDeclineRoute, { loading }] = useMutation(
    DRIVER_ACCEPT_OR_DECLINE_ROUTE_MUTATION
  );

  // event handlers
  const handleDriverDecision = async (accept: boolean) => {
    await acceptOrDeclineRoute({
      variables: {
        input: {
          operatorRouteId,
          driverId,
          accept,
        },
      },
    });
    if (!accept) {
      redirectToDriverPage();
    }
  };

  if (notification && operatorRouteData) {
    history.push(
      `/driver/${driverId}/dispatch/${operatorRouteId}/notifications`
    );
  }

  const redirectToDriverPage = () => {
    history.push(`/driver/${driverId}`);
  };

  const inFeedback = useMemo(
    () =>
      operatorRoute?.statusSlug === "done" &&
      !operatorRoute?.driverCloseoutStartDateTime &&
      !location.pathname.endsWith("feedback"),
    [operatorRoute, location.pathname]
  );

  const isShuttleTrip = useMemo(
    () =>
      [TripCategory.ShuttlePickUp, TripCategory.ShuttleReturn].includes(
        trip?.tripCategory
      ),
    [trip]
  );

  useEffect(() => {
    if (inFeedback) {
      history.replace(`${location.pathname}/feedback`);
    }
  }, [history, inFeedback, location]);

  if (operatorRouteError || operatorRouteData === null) {
    redirectToDriverPage();
    return <ErrorPage />;
  }

  // prevents flashing screen on refetch
  if ((!operatorRouteData && operatorRouteLoading) || isNotificationLoading) {
    return <Loading />;
  }

  // derived state
  const companyName = request.company?.name || null;

  const {
    passengerName,
    greetingSignName,
    passengerMobilePhone,
    isTemporaryPassenger,
  } = getPassengerInfoFromTrip(trip);

  const isFarmee = farmRelationship === FarmRelationshipEnum.Farmee;
  const affiliateIsExternal =
    farmAffiliateVariant === FarmAffiliateVariantEnum.ExternalOperator;
  const affiliateIsAi =
    farmAffiliateVariant === FarmAffiliateVariantEnum.MoovsAiOperator;
  const helmetTitle = isFarmee ? "Moovs Driver" : operatorName;

  const isCancelledTrip = !!trip.cancelledAt;

  const luggage = {
    carryOnLuggage: carryOnLuggage,
    checkedLuggage: checkedLuggage,
    oversizeLuggage: oversizeLuggage,
  };

  return (
    <>
      <Helmet>
        <title>{helmetTitle || "Moovs Driver"}</title>
      </Helmet>
      {isCancelledTrip ? (
        <TripCancelledView driverId={driverId} />
      ) : (
        <Box
          display="flex"
          minHeight="100%"
          flexDirection="column"
          margin={0}
          position="relative"
        >
          <Box
            display="flex"
            minHeight="100%"
            justifyContent="center"
            paddingBottom="94px"
          >
            <Box
              display="flex"
              flexDirection="column"
              flex="1"
              maxWidth={theme.breakpoints.values.sm}
            >
              <Box bgcolor={white}>
                <Box m={0.5}>
                  <IconButton onClick={redirectToDriverPage} size="large">
                    <CrossIcon color={primaryMainColor} />
                  </IconButton>
                </Box>
                <Box mb={2} mt={1} mx={2}>
                  <Box mb={3}>
                    <HeaderBlock
                      trip={trip}
                      tripType={startCase(lowerCase(request.type))}
                      amountDue={operatorRoute.amountDue}
                    />
                  </Box>
                  <Box mx={-2}>
                    <Divider />
                  </Box>
                  <Box mt={3} mb={2}>
                    <DriverNoteBlock note={operatorRoute.driverNote} />
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box mx={2}>
                <FarmBlock
                  isFarmee={isFarmee}
                  farmAffiliate={
                    affiliateIsExternal || affiliateIsAi
                      ? ({
                          operatorName,
                          operatorPhone,
                        } as ExternalOperator)
                      : farmAffiliate
                  }
                />
                <Box my={3}>
                  <PassengerContactBlock
                    name={passengerName}
                    greetingSignName={greetingSignName}
                    isTemporaryPassenger={isTemporaryPassenger}
                    phone={passengerMobilePhone}
                    shouldConcealPassengerInfo={shouldConcealPassengerInfo({
                      trip,
                    })}
                    companyName={companyName}
                  />
                </Box>
                <Box my={3}>
                  <TripNotesBlock
                    note={trip.note}
                    luggage={luggage}
                    childSeats={trip.childSeats}
                  />
                </Box>
                <Box my={3}>
                  <TripAlertsBlock alerts={operatorRoute.alert} />
                </Box>
                <Box my={3}>
                  <TripPlannerBlock stops={trip.stops} />
                </Box>
                <Box my={3}>
                  <VehicleBlock vehicle={operatorRoute.vehicle} />
                </Box>
              </Box>
            </Box>
          </Box>
          {(operatorRoute.statusSlug !== "done" ||
            operatorRoute?.driverStatus?.name === "PENDING") && (
            <Box
              position="fixed"
              bottom="0"
              width="100%"
              height={mobileView ? "102px" : "94px"}
              flex="1"
              alignItems="flex-start"
              display="flex"
              justifyContent="center"
              style={{
                boxShadow: `0 -1px 0 0 ${grayMedium}`,
                zIndex: 1001,
                backgroundColor: theme.palette.background.default,
              }}
            >
              <Box
                display="flex"
                flex="1"
                flexDirection="column"
                my={1.5}
                mx={2}
                justifyContent="center"
                alignItems="center"
                style={{ color: grayDark }}
                maxWidth={theme.breakpoints.values.sm}
              >
                {operatorRoute?.driverStatus?.name === "PENDING" ? (
                  <>
                    <Box mb={1}>
                      <Typography variant="overline">Pending Job</Typography>
                    </Box>
                    <Box
                      display="flex"
                      flex={1}
                      flexDirection="row"
                      width="100%"
                    >
                      <Button
                        disabled={loading}
                        fullWidth
                        variant="outlined"
                        color="primary"
                        style={{ marginRight: 4 }}
                        endIcon={
                          <CrossIcon size="small" color={primaryMainColor} />
                        }
                        onClick={() => handleDriverDecision(false)}
                      >
                        Decline
                      </Button>
                      <Button
                        disabled={loading}
                        disableElevation
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: 4 }}
                        endIcon={<CheckIcon size="small" color={white} />}
                        onClick={() => handleDriverDecision(true)}
                      >
                        Accept
                      </Button>
                    </Box>
                  </>
                ) : isShuttleTrip ? (
                  <Box>
                    <Typography>
                      Shuttle trips must be completed using the Moovs Driver app
                    </Typography>
                  </Box>
                ) : (
                  <>
                    <Box mb={1}>
                      <Typography variant="overline">Your Status</Typography>
                    </Box>
                    <RouteStatusSelect
                      statusSlug={operatorRoute.statusSlug}
                      operatorRouteId={operatorRouteId}
                      operatorRouteRefetch={operatorRouteRefetch}
                    />
                  </>
                )}
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

export default DispatchPage;
