/**
 * @file useDriver.ts
 * Hook that abstracts Driver query.
 */

import { useQuery } from "@apollo/client";

import { LOAD_DRIVER } from "../graphql";
import { Driver } from "../../types";
import { useParams } from "react-router-dom";

function useDriver() {
  // hooks
  const { driverId } = useParams<{ driverId: string }>();

  const { data, loading, error } = useQuery(LOAD_DRIVER, {
    variables: {
      id: driverId,
    },
    skip: !driverId,
  });

  const driver: Driver = data?.node;

  return { driver, driverLoading: loading, driverError: error };
}

export { useDriver };
