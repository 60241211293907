import first from "lodash/first";
import moment from "moment-timezone";
import { Trip } from "../../types";

import { getLocalTimeInUTC } from "./time";

/**
 * Hide / show passenger info depends on conditions. Will only
 * be true for a FARMED route.
 *
 * Hide if 1 hour or more before pick-up time
 * Hide if 6 hours or more past drop-off time (actual or estimated)
 */
type ShouldConcealPassengerInfoParams = {
  trip: Trip;
};

export const shouldConcealPassengerInfo = (
  params: ShouldConcealPassengerInfoParams
) => {
  const { trip } = params;

  const { isFarmedRoute } = first(trip.routes);

  if (!isFarmedRoute) {
    return false;
  }

  const pickUpTime = moment.utc(first(trip.stops).dateTime);
  const dropOffTime = moment
    .utc(pickUpTime)
    .add(
      trip.useTotalDuration ? trip.totalDuration : trip.estimatedDuration,
      "minutes"
    );

  const currentLocalTimeInUTC = getLocalTimeInUTC();

  // hide if now < pick-up dateTime - 1h
  const oneHourBeforePickupTime = pickUpTime.add(-1, "hour");
  if (currentLocalTimeInUTC.isBefore(oneHourBeforePickupTime, "second")) {
    return true;
  }

  const sixHoursAfterDropOffTime = dropOffTime.add(6, "hours");

  // hide if drop-off dateTime + 6 < now
  if (sixHoursAfterDropOffTime.isBefore(currentLocalTimeInUTC, "second")) {
    return true;
  }

  return false;
};
