import React from "react";

import { Box, CircularProgress } from "@mui/material";

const Loading = () => (
  <Box
    position="fixed"
    display="flex"
    flexDirection="column"
    flex="1"
    height="100%"
    width="100%"
    alignItems="center"
    justifyContent="center"
  >
    <CircularProgress />
  </Box>
);

export default Loading;
