import React from "react";

import { Box, Typography } from "@mui/material";

import { Vehicle } from "types";
import VehicleCard from "./VehicleCard";

type VehicleNotificationProps = {
  vehicle: Vehicle;
  oldVehicle?: Vehicle;
};

function VehicleNotification(props: VehicleNotificationProps) {
  const { vehicle, oldVehicle } = props;

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h3">Vehicle</Typography>
        <Box my={2}>
          {vehicle && <VehicleCard vehicle={vehicle} oldVehicle={oldVehicle} />}
        </Box>
      </Box>
    </Box>
  );
}

export default VehicleNotification;
