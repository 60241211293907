import React from "react";
import { Drawer, Box, Typography, useTheme, ButtonBase } from "@mui/material";
import { CrossIcon } from "globals/design-system/icons";
import { grayDark } from "globals/design-system/colors";
import { Stop } from "types";

type StopDetailsDrawerProps = {
  stopDetailsIndex: number | null;
  setStopDetailsIndex: (index: number | null) => void;
  stops: Stop[];
};

const StopDetailsDrawer = (props: StopDetailsDrawerProps) => {
  const theme = useTheme();

  const { stopDetailsIndex, setStopDetailsIndex, stops } = props;
  return (
    <Drawer
      anchor="bottom"
      open={stopDetailsIndex !== null}
      onClose={() => setStopDetailsIndex(null)}
      sx={{
        "& .MuiDrawer-paper": {
          borderTopLeftRadius: "14px",
          borderTopRightRadius: "14px",
        },
      }}
    >
      <Box display="flex" flex="1" justifyContent="center">
        <Box
          m={2}
          display="flex"
          flex="1"
          justifyContent="center"
          flexDirection="column"
          maxWidth={theme.breakpoints.values.sm}
        >
          <Box
            height="34px"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            flex="1"
          >
            <Typography variant="h3">Stop Details</Typography>
            <Box height="24px">
              <ButtonBase onClick={() => setStopDetailsIndex(null)}>
                <CrossIcon />
              </ButtonBase>
            </Box>
          </Box>
          <Box mt={2} mb={1}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: grayDark }}
            >
              Total Stop Group Size
            </Typography>
          </Box>
          <Typography variant="body2">
            {(stops[stopDetailsIndex] && stops[stopDetailsIndex].groupSize) ||
              "-"}
          </Typography>
          <Box mt={2} mb={1}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: grayDark }}
            >
              Stop Notes
            </Typography>
          </Box>
          <Box mb={1.5}>
            <Typography variant="body2">
              {(stops[stopDetailsIndex] && stops[stopDetailsIndex].note) || "-"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default StopDetailsDrawer;
