import React from "react";
import moment from "moment-timezone";

import { Box, Typography, CircularProgress, Button } from "@mui/material";

import { moovsBlue } from "globals/design-system/colors";
import { SyncIcon } from "globals/design-system/icons";
import { RoundChip } from "components/ui";
import { FlightInfoCardContentProps } from "../../../FlightInfoCard";
import {
  AirportInfo,
  FlightProgressDisplay,
  flightStatusMap,
} from "../components";

function FlightInfoCardContentMobile(props: FlightInfoCardContentProps) {
  const {
    airlineHeader,
    refreshFlightProps = {},
    trackedFlight,
    flightNumberCustomStyle,
  } = props;

  const { origin, destination, flightStatus, updatedAt } = trackedFlight;
  const { isRefreshingFlight, onClickRefreshFlight } = refreshFlightProps;

  const { statusColor, allowRefresh } = flightStatusMap(flightStatus);

  return (
    <>
      <Box
        pb={2}
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
      >
        <Box
          display="flex"
          alignItems="space-between"
          justifyContent="space-between"
          mb={2}
        >
          <Typography sx={flightNumberCustomStyle} variant="body2">
            {airlineHeader}
          </Typography>
          {isRefreshingFlight ? (
            <Box
              display="flex"
              height="42px"
              width="42px"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size={20} thickness={4} />
            </Box>
          ) : (
            !!onClickRefreshFlight &&
            allowRefresh && (
              <Button
                onClick={onClickRefreshFlight}
                size="large"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  my: -1,
                  mr: -1,
                }}
              >
                <SyncIcon color={moovsBlue} />
                <Typography
                  variant="caption"
                  fontWeight={500}
                  color={moovsBlue}
                >
                  Refresh
                </Typography>
              </Button>
            )
          )}
        </Box>
        <Box display="flex" width="100%" justifyContent="space-between">
          <RoundChip
            label={flightStatus}
            color="white"
            backgroundColor={statusColor}
            textTransform="uppercase"
          />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            textAlign="right"
            ml={1}
          >
            <Typography sx={flightNumberCustomStyle} variant="h6">
              Updated:
            </Typography>
            <Typography variant="caption">
              {moment(updatedAt).calendar()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box mb={3}>
        <FlightProgressDisplay flightStatus={flightStatus} />
        <Box pt={2} display="flex" justifyContent="space-between">
          <AirportInfo
            airportCode={origin.airport.iataCode || "-"}
            airportName={origin.airport.airportName || ""}
            transitStopType="origin"
          />
          <AirportInfo
            airportCode={destination.airport.iataCode || "-"}
            airportName={destination.airport.airportName || ""}
            transitStopType="destination"
          />
        </Box>
      </Box>
    </>
  );
}

export default FlightInfoCardContentMobile;
