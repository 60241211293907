import React from "react";
import moment from "moment-timezone";
import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import startCase from "lodash/startCase";
import lowerCase from "lodash/lowerCase";
import first from "lodash/first";

import { Box, Divider, IconButton, useTheme } from "@mui/material";

import { white } from "globals/design-system/colors";
import { CheckIcon, CrossIcon } from "globals/design-system/icons";
import { primaryMainColor } from "globals/design-system/theme";
import {
  ExternalOperator,
  FarmAffiliateVariantEnum,
  FarmRelationshipEnum,
  MetadataUnion,
} from "types";
import { shouldConcealPassengerInfo } from "globals/utils/shouldConcealPassengerInfo";
import TripAlertsBlock from "components/shared/TripAlertsBlock";
import FarmBlock from "components/shared/FarmBlock";
import PassengerContactBlock from "components/shared/PassengerContactBlock";
import AmountDueBlock from "components/shared/AmountDueBlock";
import PassengerCountBlock from "components/shared/PassengerCountBlock";
import EstimatedDurationBlock from "components/shared/EstimatedDurationBlock";
import {
  DriverNoteNotification,
  TripPlannerNotification,
  VehicleNotification,
  TripTypeNotification,
  TripNotesNotification,
  TripDateNotification,
  TripTimeNotification,
} from "./components";
import { BottomButton } from "components/layout";
import { getPassengerInfoFromTrip } from "globals/utils/helpers";
import Loading from "components/shared/Loading";
import ErrorPage from "components/shared/ErrorPage";
import useOperatorRoute from "globals/hooks/useOperatorRoute";
import useNotification from "globals/hooks/useNotification";
import { useMutation } from "@apollo/client";
import { ACKNOWLEDGE_TRIP_CHANGES_NOTIFICATION_MUTATION } from "globals/graphql/tripChangesNotifications.graphql";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { useSnackbar } from "globals/hooks/useSnackbar";
import { useAnalytics } from "globals/hooks/useAnalytics";

function TripChangeNotificationPage() {
  // hooks
  const { operatorRouteId, driverId } = useParams<{
    operatorRouteId: string;
    driverId: string;
  }>();

  const history = useHistory();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const theme = useTheme();
  const { notification, loading } = useNotification(operatorRouteId);

  const redirectToDriverPage = () => {
    history.push(`/driver/${driverId}`);
  };

  const redirectToDispatchPage = () => {
    history.push(`/driver/${driverId}/dispatch/${operatorRouteId}`);
  };

  // queries
  const { operatorRouteData, operatorRouteError, operatorRouteLoading } =
    useOperatorRoute(operatorRouteId, { pollingEnabled: true });

  //mutations

  const [
    acknowledgeTripChange,
    { loading: isUpdatingTripChangesNotification },
  ] = useMutation(ACKNOWLEDGE_TRIP_CHANGES_NOTIFICATION_MUTATION, {
    onError(error) {
      const errorMessage =
        getErrorMessage(error) || "Error updating trip changes notification";

      snackbar.error(errorMessage);
    },
    onCompleted() {
      track("trips_updatesAcknowledged");
      redirectToDispatchPage();
    },
  });

  const handleAcknowledgeTripChangesNotification = () => {
    acknowledgeTripChange({
      variables: {
        input: {
          operatorRouteId,
          notificationRecipientId: notification.notificationRecipientId,
        },
      },
    });
  };

  const operatorRoute = operatorRouteData?.driverRoute;
  const { name: operatorName, voicePhoneNumber: operatorPhone } =
    operatorRouteData?.driverRoute?.operator || {};
  const trip = operatorRoute?.trip;
  const request = operatorRoute?.request;
  const {
    farmRelationship,
    farmAffiliate,
    farmAffiliateVariant,
    carryOnLuggage,
    checkedLuggage,
    oversizeLuggage,
  } = trip?.routes[0] || {};

  if (operatorRouteError || operatorRouteData === null) {
    return <ErrorPage />;
  }

  if (!notification && !loading) {
    redirectToDriverPage();
  }

  // prevents flashing screen on refetch
  if ((!operatorRouteData && operatorRouteLoading) || loading) {
    return <Loading />;
  }

  const companyName = request.company?.name || null;

  const {
    passengerName,
    greetingSignName,
    passengerMobilePhone,
    isTemporaryPassenger,
  } = getPassengerInfoFromTrip(trip);

  const isFarmee = farmRelationship === FarmRelationshipEnum.Farmee;
  const affiliateIsExternal =
    farmAffiliateVariant === FarmAffiliateVariantEnum.ExternalOperator;
  const affiliateIsAi =
    farmAffiliateVariant === FarmAffiliateVariantEnum.MoovsAiOperator;
  const helmetTitle = isFarmee ? "Moovs Driver" : operatorName;

  const luggage = {
    carryOnLuggage: carryOnLuggage,
    checkedLuggage: checkedLuggage,
    oversizeLuggage: oversizeLuggage,
  };

  const metadata = notification?.metadata;

  const {
    orderTypePrevious,
    driverNotePrevious,
    forwardFacingSeatQuantityPrevious,
    rearFacingSeatQuantityPrevious,
    boosterSeatQuantityPrevious,
    swoopNotePrevious,
    vehiclePrevious,
    stopsPrevious = [],
  } = (metadata || {}) as MetadataUnion;

  const pickUpStop = first(trip.stops);
  const previousPickupStop = first(stopsPrevious);

  const pickUpDate = moment.utc(pickUpStop.dateTime).format("M/DD/YY");
  const previousPickUpDate =
    previousPickupStop?.dateTime &&
    moment.utc(previousPickupStop.dateTime).format("M/DD/YY");

  const pickUpTime = moment.utc(pickUpStop.dateTime).format("h:mm A");
  const previousPickUpTime =
    previousPickupStop?.dateTime &&
    moment.utc(previousPickupStop.dateTime).format("h:mm A");

  return (
    <>
      <Helmet>
        <title>{helmetTitle || "Moovs Driver"}</title>
      </Helmet>

      <Box
        display="flex"
        minHeight="100%"
        flexDirection="column"
        margin={0}
        position="relative"
      >
        <Box
          display="flex"
          minHeight="100%"
          justifyContent="center"
          paddingBottom="94px"
        >
          <Box
            display="flex"
            flexDirection="column"
            flex="1"
            maxWidth={theme.breakpoints.values.sm}
          >
            <Box bgcolor={white}>
              <Box m={0.5}>
                <IconButton onClick={redirectToDriverPage} size="large">
                  <CrossIcon color={primaryMainColor} />
                </IconButton>
              </Box>
              <Box mb={2} mt={1} mx={2}>
                <Box mb={2}>
                  <Box display="flex" flexDirection="row" mb={2}>
                    <TripDateNotification
                      tripDate={pickUpDate}
                      previousTripDate={previousPickUpDate}
                    />
                    <TripTimeNotification
                      pickUpTime={pickUpTime}
                      pickUpPreviousTime={previousPickUpTime}
                      flightOffset={pickUpStop.flightOffset}
                    />
                    <AmountDueBlock amountDue={operatorRoute.amountDue} />
                  </Box>
                  <Box my={2}>
                    <Divider />
                  </Box>

                  <Box display="flex" flexDirection="row" flexWrap="wrap">
                    <PassengerCountBlock totalGroupSize={trip.totalGroupSize} />
                    <TripTypeNotification
                      tripType={startCase(lowerCase(request.type))}
                      oldTripType={orderTypePrevious}
                    />

                    <EstimatedDurationBlock
                      tripEstimatedDuration={trip.estimatedDuration}
                      tripTotalDuration={trip.totalDuration}
                      shouldUseTripTotalDuration={trip.useTotalDuration}
                    />
                  </Box>
                </Box>
                <Box mx={-2}>
                  <Divider />
                </Box>
                <Box mt={3}>
                  <DriverNoteNotification
                    note={operatorRoute.driverNote}
                    previousNote={driverNotePrevious}
                  />
                </Box>
              </Box>
            </Box>
            <Divider />

            <Box mx={2}>
              <FarmBlock
                isFarmee={isFarmee}
                farmAffiliate={
                  affiliateIsExternal || affiliateIsAi
                    ? ({
                        operatorName,
                        operatorPhone,
                      } as ExternalOperator)
                    : farmAffiliate
                }
              />

              <Box my={3}>
                <PassengerContactBlock
                  name={passengerName}
                  greetingSignName={greetingSignName}
                  isTemporaryPassenger={isTemporaryPassenger}
                  phone={passengerMobilePhone}
                  shouldConcealPassengerInfo={shouldConcealPassengerInfo({
                    trip,
                  })}
                  companyName={companyName}
                />
              </Box>
              <Box my={3}>
                <TripNotesNotification
                  note={trip.note}
                  oldNote={swoopNotePrevious}
                  luggage={luggage}
                  oldChildSeats={{
                    boosterSeatQuantity: boosterSeatQuantityPrevious,
                    forwardFacingSeatQuantity:
                      forwardFacingSeatQuantityPrevious,
                    rearFacingSeatQuantity: rearFacingSeatQuantityPrevious,
                  }}
                  childSeats={trip.childSeats}
                />
              </Box>
              <Box my={3}>
                <TripAlertsBlock alerts={operatorRoute.alert} />
              </Box>
              <Box my={3}>
                <TripPlannerNotification
                  stops={trip.stops}
                  oldStops={stopsPrevious}
                />
              </Box>
              <Box my={3}>
                <VehicleNotification
                  vehicle={operatorRoute.vehicle}
                  oldVehicle={vehiclePrevious}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <BottomButton
          text="OK"
          rightIcon={<CheckIcon />}
          disabled={isUpdatingTripChangesNotification}
          onClick={handleAcknowledgeTripChangesNotification}
        />
      </Box>
    </>
  );
}

export default TripChangeNotificationPage;
