import React from "react";

export const OutdatedIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M13.3333 20C15.0474 20 16.723 19.5015 18.1483 18.5675C19.5735 17.6335 20.6843 16.306 21.3403 14.7528C21.9962 13.1996 22.1679 11.4906 21.8335 9.84173C21.4991 8.1929 20.6736 6.67834 19.4616 5.4896C18.2495 4.30085 16.7053 3.4913 15.0241 3.16333C13.3429 2.83535 11.6004 3.00368 10.0167 3.64703C8.43312 4.29037 7.07957 5.37984 6.12726 6.77766C5.17496 8.17547 4.66667 9.81886 4.66667 11.5V12.1538M2 9.53585L4.66667 12.1512L7.33333 9.53585M12.6667 6.5V12.3846H17.3333"
        stroke="#195FE9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
