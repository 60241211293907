/**
 * @file FlightInfoCard.tsx
 * Renders info about a future or upcoming flight.
 * Flight info comes from flight aware.
 *
 * components:
 *  FlightInfoCard
 */

import React from "react";
import {
  Box,
  useTheme,
  useMediaQuery,
  Button,
  Typography,
} from "@mui/material";

import { grayLight, moovsBlue } from "globals/design-system/colors";
import { OpenIcon } from "globals/design-system/icons";
import { useAnalytics } from "globals/hooks/useAnalytics";
import {
  FlightDetails,
  DivertedFlightInfo,
  FlightInfoCardContentMobile,
  FlightInfoCardContentDesktop,
} from "./components";
import { TrackedFlight } from "types";

export type FlightInfoCardContentProps = {
  refreshFlightProps: {
    isRefreshingFlight?: boolean;
    onClickRefreshFlight?: () => void;
  };
  airlineHeader: string;
  trackedFlight: TrackedFlight;
  flightNumberCustomStyle: {
    color: string;
    fontWeight: number;
  };
};

type FlightInfoCardProps = {
  trackedFlight: TrackedFlight;
  refreshFlightProps?: {
    onClickRefreshFlight?: () => void;
    isRefreshingFlight?: boolean;
  };
};

function FlightInfoCard(props: FlightInfoCardProps) {
  const { trackedFlight, refreshFlightProps } = props;

  // hooks
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const { track } = useAnalytics();

  const {
    origin,
    destination,
    airline,
    flightNumber,
    flightStatus,
    faFlightId,
  } = trackedFlight;

  const airlineHeader = `${airline.airlineName} (${airline.iataCode}) ${flightNumber}`;

  // custom Styles
  const flightNumberCustomStyle = {
    color: theme.palette.text.primary,
    fontWeight: 600,
  };

  // event handlers
  const handleFlightAwareLink = (e: React.MouseEvent) => {
    e.stopPropagation();
    track("reservations_flightAwareOpened");

    window
      .open(`https://flightaware.com/live/flight/id/${faFlightId}`, "_blank")
      .focus();
  };

  return (
    <Box p={2} style={{ backgroundColor: grayLight, borderRadius: "4px" }}>
      {isMobileView ? (
        <FlightInfoCardContentMobile
          trackedFlight={trackedFlight}
          refreshFlightProps={refreshFlightProps}
          airlineHeader={airlineHeader}
          flightNumberCustomStyle={flightNumberCustomStyle}
        />
      ) : (
        <FlightInfoCardContentDesktop
          trackedFlight={trackedFlight}
          refreshFlightProps={refreshFlightProps}
          airlineHeader={airlineHeader}
          flightNumberCustomStyle={flightNumberCustomStyle}
        />
      )}

      <Box display="flex" justifyContent="space-between">
        <FlightDetails
          transitStop={origin}
          transitStopType="origin"
          flightStatus={flightStatus}
        />
        <FlightDetails
          transitStop={destination}
          transitStopType="destination"
          flightStatus={flightStatus}
        />
      </Box>

      {faFlightId && (
        <Box mt={2}>
          <Button
            variant="outlined"
            fullWidth
            endIcon={<OpenIcon size="small" color={moovsBlue} />}
            onClick={handleFlightAwareLink}
          >
            <Typography variant="body2" fontWeight={500}>
              FlightAware
            </Typography>
          </Button>
        </Box>
      )}

      {flightStatus === "DIVERTED" && (
        <DivertedFlightInfo flightNumber={trackedFlight.flightNumber} />
      )}
    </Box>
  );
}

export default FlightInfoCard;
