import React from "react";
import isNil from "lodash/isNil";

import { Box, Typography } from "@mui/material";

import {
  grayDark,
  moovsBlueLightNotification,
  moovsBlueSelectedNotification,
} from "globals/design-system/colors";
import NotificationIcon from "globals/design-system/icons/info/NotificationIcon";

type DriverNoteBlockProps = {
  note: string;
  previousNote: string;
};

const DriverNoteNotification = (props: DriverNoteBlockProps) => {
  const { note, previousNote } = props;

  const hasNotification = !isNil(previousNote);
  return (
    <Box
      mb={1}
      {...(hasNotification
        ? {
            border: 1,
            borderColor: moovsBlueLightNotification,
            bgcolor: moovsBlueSelectedNotification,
            borderRadius: 1,
            width: "100%",
            p: 1,
          }
        : {})}
    >
      <Box display="flex" alignItems="center" gap={0.8}>
        <Typography variant="h3">Driver Note</Typography>
        {hasNotification && <NotificationIcon />}
      </Box>
      <Box my={1.5}>
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "break-spaces",
            color: !note && grayDark,
            fontSize: "14px",
          }}
        >
          {note || `No driver's note`}
        </Typography>

        {hasNotification && (
          <Typography
            variant="body2"
            display="flex"
            style={{
              marginTop: 4,
              fontWeight: 400,
              lineHeight: "16px",
              fontSize: "12px",

              color: grayDark,
            }}
          >
            <Box mr={0.5}>Original:</Box>
            <Typography
              variant="inherit"
              sx={{ textDecorationLine: "line-through" }}
            >
              {previousNote || `No driver's note`}
            </Typography>
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default DriverNoteNotification;
