import React, { useState, MouseEvent } from "react";
import { useMutation } from "@apollo/client";

import { styled } from "@mui/material/styles";
import { Box, Button, Menu, MenuItem, CircularProgress } from "@mui/material";

import AlertDialog from "./AlertDialog";
import { rideStatusLabelColors, grayLight } from "globals/design-system/colors";
import {
  InProgressIcon,
  ChevronRightIcon,
  ReadyIcon,
} from "globals/design-system/icons";
import { UPDATE_ROUTE_DISPATCH_MUTATION } from "globals/graphql";
import { useSnackbar } from "globals/hooks/useSnackbar";
import { DispatchStatusEnum } from "globals/utils/helpers";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

type RouteStatusSelectProps = {
  statusSlug: string;
  operatorRouteId: string;
  operatorRouteRefetch: Function;
};

function RouteStatusSelect(props: RouteStatusSelectProps) {
  const { statusSlug, operatorRouteId, operatorRouteRefetch } = props;

  const snackbar = useSnackbar();

  // state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [confirmDoneDialogOpen, setConfirmDoneDialogOpen] = useState(false);

  // mutations
  const [updateRouteDispatch, { loading }] = useMutation(
    UPDATE_ROUTE_DISPATCH_MUTATION,
    {
      onError(error) {
        const errorMessage =
          getErrorMessage(error) || "Error updating route status";

        snackbar.error(errorMessage);
        console.error("mutation error", error);
      },
    }
  );

  // event handlers
  const handleClickListItem = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleRouteStatusClick = (slug: string) => {
    if (slug === "done") {
      setConfirmDoneDialogOpen(true);
      return;
    }

    updateRouteStatus(slug);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDoneDialogOpen(false);
    handleCloseMenu();
  };

  const handleCloseOutRideClick = () => {
    updateRouteStatus("done");
    setConfirmDoneDialogOpen(false);
  };

  const updateRouteStatus = async (slug: string) => {
    setAnchorEl(null);

    await updateRouteDispatch({
      variables: {
        input: {
          routeId: operatorRouteId,
          statusSlug: slug,
        },
      },
    });

    if (slug !== "done") operatorRouteRefetch();
  };

  const { color, backgroundColor } = (rideStatusLabelColors as any)[statusSlug];

  // custom Styles
  const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    margin: "0 8px",
    padding: "2px",
    display: "flex",
    flex: 1,
    borderRadius: "4px",
    height: 44,
    fontSize: 16,
    fontWeight: 500,
    "&:hover": {
      backgroundColor: grayLight,
    },
  }));

  return (
    <>
      <Button
        fullWidth
        style={{ color, backgroundColor }}
        onClick={handleClickListItem}
        startIcon={
          <Box ml={1} display="flex">
            <InProgressIcon color={color} />
          </Box>
        }
        endIcon={
          loading ? (
            <CircularProgress
              style={{ color: color, marginRight: 12 }}
              size={20}
            />
          ) : (
            <ChevronRightIcon color={color} />
          )
        }
      >
        <Box display="flex" flex="1">
          {(DispatchStatusEnum as any)[statusSlug]}
        </Box>
      </Button>
      <Menu
        keepMounted
        id="route-status-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
          },
        }}
      >
        {Object.entries(DispatchStatusEnum)
          .filter(([_, name]) => name !== DispatchStatusEnum.cancelled)
          .map(([slug, name]) => {
            const active = slug === statusSlug;

            const menuItemColor = (rideStatusLabelColors as any)[slug].color;

            return (
              <StyledMenuItem
                key={slug}
                onClick={() => handleRouteStatusClick(slug)}
                selected={active}
              >
                <Box
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  mx={2}
                >
                  {active ? (
                    <InProgressIcon color={menuItemColor} size="small" />
                  ) : (
                    <ReadyIcon color={menuItemColor} size="small" />
                  )}
                </Box>
                <Box mx={1}>{name}</Box>
              </StyledMenuItem>
            );
          })}
      </Menu>
      <AlertDialog
        open={confirmDoneDialogOpen}
        onClose={handleCloseConfirmDialog}
        onAccept={handleCloseOutRideClick}
        closeButtonText="Cancel"
        acceptButtonText="Mark Done"
        title="Mark Trip as Done?"
        content="Marking this trip as done will permanantly close out this trip. Are you sure you want
        to end this trip?"
      />
    </>
  );
}

export default RouteStatusSelect;
