/**
 * @file useAnalytics.ts
 * hook that abstracts segment analytics calls
 */

import { fromGlobalId } from "../utils/fromGlobalId";
import { useDriver } from "./useDriver";
import { useSegment } from "./useSegment";

function useAnalytics() {
  // hooks
  const { driver } = useDriver();
  const analytics = useSegment();

  const isDevelopment = process.env.NODE_ENV === "development";
  const operatorId = driver?.operator?.id;

  const track = (name: string, options?: {}) => {
    // show events on dev
    if (isDevelopment) console.log("track: ", name);

    analytics.track(name, {
      source: "driver",
      ...options,
      ...(driver && { driver_id: fromGlobalId(driver.id).id }),
      ...(operatorId && { operator_id: fromGlobalId(operatorId).id }),
    });
  };

  return { track };
}

export { useAnalytics };
