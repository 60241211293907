import React from "react";
import moment from "moment-timezone";

import { Box, Typography, CircularProgress, Button } from "@mui/material";

import { grayMedium, moovsBlue } from "globals/design-system/colors";
import { SyncIcon } from "globals/design-system/icons";
import { FlightInfoCardContentProps } from "../../../FlightInfoCard";
import { RoundChip } from "components/ui";
import {
  AirportInfo,
  FlightProgressDisplay,
  flightStatusMap,
} from "../components";

function FlightInfoCardContentDesktop(props: FlightInfoCardContentProps) {
  const {
    airlineHeader,
    refreshFlightProps = {},
    trackedFlight,
    flightNumberCustomStyle,
  } = props;

  const { origin, destination, flightStatus, updatedAt } = trackedFlight;
  const { isRefreshingFlight, onClickRefreshFlight } = refreshFlightProps;

  const { statusColor, allowRefresh } = flightStatusMap(flightStatus);

  return (
    <>
      <Box
        pb={2}
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        sx={{
          borderBottomWidth: "1px",
          borderBottomColor: grayMedium,
          borderBottomStyle: "solid",
        }}
      >
        <Box>
          <Box mb={1}>
            <Typography sx={flightNumberCustomStyle} variant="body2">
              {airlineHeader}
            </Typography>
          </Box>
          <RoundChip
            label={flightStatus}
            color="white"
            backgroundColor={statusColor}
            textTransform="uppercase"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          gap={2}
        >
          {isRefreshingFlight ? (
            <Box
              display="flex"
              height="42px"
              width="42px"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size={20} thickness={4} />
            </Box>
          ) : (
            <Box>
              {!!onClickRefreshFlight && allowRefresh && (
                <Button
                  onClick={onClickRefreshFlight}
                  size="large"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    my: -1,
                    mr: -1,
                  }}
                >
                  <SyncIcon color={moovsBlue} />
                  <Typography
                    variant="caption"
                    fontWeight={500}
                    color={moovsBlue}
                  >
                    Refresh
                  </Typography>
                </Button>
              )}
            </Box>
          )}

          {updatedAt && (
            <Box display="flex" flexDirection="column" textAlign="right">
              <Typography sx={flightNumberCustomStyle} variant="h6">
                Updated:
              </Typography>
              <Typography variant="caption">
                {moment(updatedAt).calendar()}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box py={2} display="flex" justifyContent="space-between">
        <Box flex={1}>
          <AirportInfo
            airportCode={origin.airport.iataCode || "-"}
            airportName={origin.airport.airportName || ""}
            transitStopType="origin"
          />
        </Box>
        <Box flex={3}>
          <FlightProgressDisplay flightStatus={flightStatus} />
        </Box>
        <Box flex={1}>
          <AirportInfo
            airportCode={destination.airport.iataCode || "-"}
            airportName={destination.airport.airportName || ""}
            transitStopType="destination"
          />
        </Box>
      </Box>
    </>
  );
}

export default FlightInfoCardContentDesktop;
