/**
 * @file graphql/trackedFlight.graphql.ts
 * queries/mutations for tracked flights.
 *
 */

import { gql } from "@apollo/client";

export const UPDATE_TRACKED_FLIGHT_MUTATION = gql`
  mutation UpdateTrackedFlight($input: UpdateTrackedFlightInput!) {
    updateTrackedFlight(input: $input) {
      trackedFlight {
        id
        faFlightId
        updatedAt
        airline {
          airlineName
          iataCode
        }
        actualAirline {
          airlineName
          iataCode
        }
        flightStatus
        flightNumber
        actualFlightNumber
        origin {
          id
          airport {
            airportName
            iataCode
            icaoCode
            countryName
            countryIso2
            timezone
            gmt
          }
          scheduledGate
          scheduledDateTime
          scheduledTerminal
          actualGate
          actualDateTime
          estimatedDateTime
          actualTerminal
        }
        destination {
          id
          airport {
            airportName
            iataCode
            icaoCode
            countryName
            countryIso2
            timezone
            gmt
          }
          scheduledGate
          scheduledDateTime
          scheduledTerminal
          actualGate
          estimatedDateTime
          actualDateTime
          actualTerminal
        }
      }
    }
  }
`;
