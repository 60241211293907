import moment from "moment-timezone";
import { Stop, TransitStop } from "types";

type FlightTime = {
  timeLabel: "Actual" | "Estimated" | "Scheduled";
  time: Date;
};

/**
 * Reduces scheduled, estimated, and actual tracked flight
 * times into a queue based on importance. Filters out results that
 * do not exist.
 */
export const getFlightTimePriorityQueue = (
  transitStop: TransitStop
): FlightTime[] => {
  const { actualDateTime, estimatedDateTime, scheduledDateTime, airport } =
    transitStop;

  const flightTimePriorityQueue = [];

  // use either actual or estimated, never both
  if (actualDateTime) {
    flightTimePriorityQueue.push({
      timeLabel: "Actual",
      time: moment.tz(actualDateTime, airport.timezone),
    });
  } else if (estimatedDateTime) {
    flightTimePriorityQueue.push({
      timeLabel: "Estimated",
      time: moment.tz(estimatedDateTime, airport.timezone),
    });
  }

  // if scheduled render it to compare to either actual or estimated
  if (scheduledDateTime) {
    flightTimePriorityQueue.push({
      timeLabel: "Scheduled",
      time: moment.tz(scheduledDateTime, airport.timezone),
    });
  }

  return flightTimePriorityQueue;
};

/**
 * returns whether a stop is for a flight arrival or departure by
 * checking if airport name matches origin or destination. if not,
 * defaults to origin.
 */
export const getStopFlightType = (stop: Stop) => {
  const { trackedFlight } = stop;
  const { origin, destination } = trackedFlight;

  if (stop.airport.airportName === origin.airport.airportName) {
    return "origin";
  }
  if (stop.airport.airportName === destination.airport.airportName) {
    return "destination";
  }
  return "origin";
};
