import { useDriver } from "./useDriver";
import { useEffect, useState } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useLocalStorage } from "./useLocalStorage";

export function useTrackLDClient() {
  const { driver } = useDriver();
  const ldClient = useLDClient();
  const [ldKey, setLdKey] = useLocalStorage("ldKey");
  const [hasInitialized, setHasInitialized] = useState(false);

  const operator = driver?.operator;
  const shouldIdentify =
    operator?.id && ldKey?.key !== operator?.id && !hasInitialized;

  useEffect(() => {
    if (shouldIdentify) {
      ldClient.identify({
        kind: "operator",
        key: operator.id,
        name: operator.name,
        slug: operator.nameSlug,
        plan: operator.plan,
      });
      setLdKey({
        ...ldKey,
        key: operator.id,
        name: operator.name,
        plan: operator.plan,
        slug: operator.nameSlug,
      });
      setHasInitialized(true);
    }
  }, [
    hasInitialized,
    ldClient,
    ldKey,
    operator?.id,
    operator?.name,
    operator?.nameSlug,
    operator?.plan,
    setLdKey,
    shouldIdentify,
  ]);
}
