/**
 * @file MoovsApolloProvider.tsx
 * Migration from Apollo Boost to Apollo Client to enable advanced configurations of Apollo Link.
 * Adds custom driver id header for backend authentication on requests.
 * For a more complex implementation see operator app.
 *
 * components:
 *  MoovsApolloProvider
 */
import React from "react";
import {
  InMemoryCache,
  ApolloClient,
  ApolloLink,
  HttpLink,
  ApolloProvider,
} from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";

import { headerMiddlewareLink, onErrorLink } from "./ApolloLinks";
import introspection from "../possibleTypes";

const cache = new InMemoryCache({
  possibleTypes: introspection.possibleTypes,
});

const getServerLink = () => process.env.REACT_APP_API_URL;

const client = (driverId: string, history: any, link: string) =>
  new ApolloClient({
    link: ApolloLink.from([
      headerMiddlewareLink(driverId),
      onErrorLink(driverId, history),
      new HttpLink({
        uri: `${link}/driver`,
        credentials: "include",
      }),
    ]),
    connectToDevTools: process.env.NODE_ENV !== "production",
    cache,
  });

function MoovsApolloProvider(props) {
  const { children } = props;
  const { driverId } = useParams<{ driverId: string }>();
  const history = useHistory();
  const link = getServerLink();

  return (
    <ApolloProvider client={client(driverId, history, link)}>
      {children}
    </ApolloProvider>
  );
}

export default MoovsApolloProvider;
