import React from "react";

import { Box, Typography } from "@mui/material";

const ErrorPage = () => {
  return (
    <Box
      position="fixed"
      display="flex"
      flexDirection="column"
      flex="1"
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h2">Server Error</Typography>
      <Typography variant="body1">
        Please check the provided url string.
      </Typography>
    </Box>
  );
};

export default ErrorPage;
