import React from "react";

import { Typography, Box } from "@mui/material";

import emptyListIcon from "globals/design-system/illustrations/emptyListIcon.svg";

type EmptyTripsListProps = {
  activeTab: string;
};

function EmptyTripsList(props: EmptyTripsListProps) {
  const { activeTab } = props;

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <img src={emptyListIcon} alt="Empty List" />
      <Box mt={2} maxWidth={200} textAlign="center">
        <Typography variant="h3">
          {
            {
              NEW: "You currently have no new rides",
              UPCOMING: "You currently have no upcoming rides",
              COMPLETED: "You currently have no completed rides",
            }[activeTab]
          }
        </Typography>
      </Box>
    </Box>
  );
}

export default EmptyTripsList;
